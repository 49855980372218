export default {
    namespaced: true,
    state: {
        section: {
            // firstStart: {
            //     title: 'С чего начать',
            //     active: false,
            //     items:{}
            // },
            search: {
                title: 'Поиск',
                active: true,
                items: {
                    syntaxSearch: {
                        title: 'Синтаксический поиск',
                        active: false,
                        items: {
                            SearchOperators: {
                                title: 'Операторы поиска',
                                active: false,
                            },
                            Morphology: {
                                title: 'Морфология',
                                active: false,
                            },
                            Templates: {
                                title: 'Шаблоны',
                                active: false,
                            },
                        }
                    },
                    attributeSearch: {
                        title: 'Атрибутивный поиск',
                        active: false,
                        items: {
                            SearchAttributes: {
                                title: 'Атрибуты для поиска',
                                active: false,
                            },
                            FullTextSearch: {
                                title: 'Полнотекстовый поиск в атрибуте',
                                active: false,
                            },
                        }
                    },
                    queryConstructor: {
                        title: 'Конструктор запроса',
                        items: {
                            QueryConstructor: {
                                title: 'Общее описание',
                            },

                        }
                    },
                    history: {
                        title: 'История поисковых запросов',
                        items: {
                            History: {
                                title: 'Общее описание',
                            },
                        }
                    },
                    searchResult: {
                        title: 'Поисковая выдача',
                        items: {
                            SearchResultDescription: {
                                title: 'Общее описание'
                            },
                            SearchTools: {
                                title: 'Поисковые инструменты'
                            },
                            SearchFacets: {
                                title: 'Поисковые фильтры (фасеты)'
                            }
                        }
                    },
                    view360: {
                        title: 'Вид 360',
                        items: {
                            View360Start: {
                                title: 'Начало работы'
                            },
                            View360Widgets: {
                                title: 'Виджеты'
                            },
                        }
                    }

                }
            },
            analytics: {
                title: 'Аналитика',
                items: {
                    heatMap: {
                        title: 'Тепловая карта',
                        items: {
                            HeatMapColorMap: {
                                title: 'Индикатор цвета'
                            },
                            HeatMapFields: {
                                title: 'Настройка полей'
                            },
                        },
                    },
                    techSignals: {
                        title: 'Технологические сигналы',
                        items: {
                            TechSignalsDesc: {
                                title: 'Общее описание'
                            },
                            TechSignalsFields: {
                                title: 'Описание полей'
                            },
                            TechSignalsFiltering: {
                                title: 'Фильтрация и сортировка'
                            },
                            TechSignalsDiagram: {
                                title: 'Режим диаграммы'
                            },
                        },
                    },
                    techRating: {
                        title: 'Технологический рейтинг',
                        items: {
                            TechRatingDesc: {
                                title: 'Общее описание'
                            },
                            TechRatingFormatAbs: {
                                title: 'Рейтинг в формате "Абсолютные"'
                            },
                            TechRatingFormatCAGR: {
                                title: 'Рейтинг в формате "CAGR"'
                            },
                        },
                    },
                    countryRating: {
                        title: 'Рейтинг стран',
                        items: {
                            CountryRatingDesc: {
                                title: 'Общее описание'
                            },
                        }
                    },
                }
            },
            clusters: {
                title: 'Кластеры',
                allowed: 'CREATE_CLUSTERING_REPORTS',
                items: {
                    clustersStart: {
                        title: 'Начало работы',
                        items: {
                            ClusterWhatIsIt: {
                                title: 'Что такое кластерный отчет'
                            },
                            ClusterHowToBuild:{
                                title: 'Как создать отчет'
                            }
                        }
                    },
                    clustersWork:{
                        title: 'Работа с кластерами',
                        items: {
                            ClusterList: {
                                title: 'Список отчетов',
                            },
                            ClusterApp:{
                                title: 'Рабочая область'
                            },
                            ClusterCard:{
                                title: 'Карточка кластера'
                            },
                            ClusterSearch:{
                                title: 'Поиск по кластерам'
                            }
                        }

                    },
                }
            }
        },
        content: {
            searchOperators: {}

        }
    },
    mutations: {},
    actions: {}
};
