<template>
    <section class="fst-FAQ-item">

        <h3>История поисковых запросов</h3>

        <p>Вы можете просмотреть историю своих недавних поисковых запросов,
            для этого откройте дополнительные инструменты поискового запроса
            и выберите <b>Мой поиск -&gt;</b> <b>История запросов</b>.</p>

        <p><span class="image-wrap" style=""><img src="/history01.png"></span></p>

    </section>
</template>

<script>
    export default {
        name: "History",
        data() {
            return {
                section: 'search',
                subSection: 'history'
            }
        }
    }
</script>

<style scoped>

</style>