<template>
    <section class="fst-FAQ-item">

        <h3>Инструменты для работы с поисковой выдачи</h3>

        <p><b>Сортировка</b></p>

        <p>Вы можете изменить сортировку поисковой выдачи в выпадающем списке <b>Сортировка по</b>.<br>
            <span class="image-wrap" style=""><img src="/sortBy1.png"></span></p>

        <p><b>Открытие карточки документа</b></p>

        <p>Нажмите на название документа/компании, чтобы открыть карточку документа в новой вкладке и получить более
            подробную информацию.</p>

        <span class="image-wrap" style=""><img src="/openDocCard1.png"></span>

        <p>Нажмите на краткое описание документа - раскроется полный текст абстракта</p>
        <span class="image-wrap" style=""><img src="/showDesc1.png"></span>

        <p>Чтобы открыть первоисточник источник документа/компании нажмите на иконку <i
                class="fas fa-share iq-text-primary ml-1"></i>.</p>



        <p><b>Просмотренные документы</b></p>
        <p>При открытии карточки документа или раскрытии полного абстракта документ помечается как "просмотренный", при
            этом меняется    цвет заголовка </p>
        <span class="image-wrap" style="">
            <img src="/viewedDoc1.png">
            <img src="/viewedDoc2.png">
        </span>

        <p>Воспользуйтесь иконкой глаза на документе или компании,
            чтобы отменить метку "просмотренное" <i class="fas fa-eye ml-1 iq-text-primary"></i>.</p>
        <p>В меню дополнительных
            инструментов поискового запроса <b>Отображать</b> -&gt; <b>Непросмотренные документы</b>
            вы можете исключить из поисковой выдачи документы/компании, которые вы просмотрели.</p>
        <span class="image-wrap" style=""><img src="/showAllDocs1.png"></span>

        <p><b>Избранное</b></p>

        <p>Для того, чтобы добавить документ или компанию в избранное необходимо нажать на иконку тэга -&gt; придумать
            новое название тэга или выбрать из уже существующих -&gt; нажать иконку сохранить.</p>

        <p><span class="image-wrap" style=""><img src="/enterTag1.png"></span></p>

        <p>После документ/компания отобразятся в Избранном. Открыть раздел Избранное вы можете нажав на иконку в виде
            звезды в правом верхнем углу на любой вкладке раздела Поиск.</p>

        <p><span class="image-wrap" style=""><img src="/fav1.png"></span></p>

        <p><b>Заметки</b></p>

        <p>Вы можете написать комментарии/заметки к документу и компании, для этого нажмите на иконку <b>Notes</b> на
            документе или компании. После написания заметки, нажмите <b>Save</b>, чтобы сохранить.</p>

        <p><b>Перевод</b></p>

        <p>Вы можете перевести документ/компанию на русский язык, для этого нажмите <b>Translate</b> на документе или
            компании.</p>

        <p><b>Найти похожее</b></p>

        <p>Если вам нужно найти похожие документы или компании, нажмите <b>More Like This</b> на документе/компании.&nbsp;
        </p>

        <p><span class="image-wrap" style=""><img src="/notesTranslateSimilar1.png"></span></p>
    </section>
</template>

<script>
    export default {
        name: "SearchTools",
        data() {
            return {
                section: 'search',
                subSection: 'searchResult'
            }
        }
    }
</script>

<style scoped>

</style>