<template>
    <section class="fst-FAQ-item">

        <h3>Карточка кластера</h3>

        <img class="float-right mb-1 ml-2" src="/clusterCard1.png"/>

        <p>Чтобы открыть для просмотра карточку кластера необходимо кликнуть по его названию в окне со списком кластеров
            или области на карте.</p>

        <p class="d-none"><b>Примечание</b> - По умолчанию, в окне с перечнем кластеров скрыт кластер, содержащий
            документы, которые не были отнесены ни к одному из других кластеров. Чтобы отобразить данный кластер,
            необходимо
            включить переключатель "No clusters" в нижней части окна с перечнем кластеров. Кластер отобразиться на
            последней
            позиции в списке и будет называться "-1 No clusters".</p>
        <p>Карточка кластера содержит:</p>
        <ul class="dashed">
            <li>название кластера (кликнув по названию можно его изменить);</li>
            <li>количество документов в кластере и их доля в общем количестве документов за все кластера;</li>
            <li>кнопка "SHOW DOCUMENTS" - показывает в отдельной вкладке документы кластера;</li>
            <li>перечень ключевых слов, характеризующих документы кластера;</li>
            <li>распределение документов по годам.</li>
        </ul>

        <p>При одновременном выборе нескольких кластеров (выбор осуществляется с нажатой клавишей CTRL) в карточке
            кластера будут отображаться агрегированные данные.</p>


    </section>
</template>

<script>
    export default {
        name: "ClusterCard",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersWork'
            }
        }
    }
</script>

<style scoped>

</style>