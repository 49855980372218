<template>
    <section class="fst-FAQ-item">
        <h3>Шаблоны</h3>

        <p>Активация шаблона <em>«Use Case»</em> помимо поискового запроса позволяет найти документы, содержащие варианты использования интересующей пользователя темы, описанной в поисковом запросе. Это могут быть варианты использования технологии в конкретной отрасли, варианты использования компании и т. д.</p>

        <p><span class="image-wrap" style=""><img src="/template01.png" ></span></p>

        <p>Примеры результатов поиска по шаблону «Use Case»:</p>
        <div class="table-wrap">
            <table class="table"><tbody>
            <tr>
                <th>Поисковый запрос*</th>
                <th>Описание результатов поиска*</th>
            </tr>
            <tr>
                <td>robot "automotive industry"</td>
                <td>Варианты использования роботов в автомобильной промышленности</td>
            </tr>
            <tr>
                <td>IBM "artificial intelligence"</td>
                <td>Варианты использования искусственного интеллекта от IBM (для нужд клиента или для внутренних нужд IBM)</td>
            </tr>
            <tr>
                <td>Google "artificial intelligence" calls</td>
                <td>Случаи использования искусственного интеллекта от Google&nbsp; для обработки звонков</td>
            </tr>
            </tbody></table>
        </div>

        <p>*чем точнее и полнее будет запрос - тем лучше будет результат</p>

    </section>
</template>

<script>
    export default {
        name: "Templates",
        data(){
            return{
                section: 'search',
                subSection: 'syntaxSearch'
            }
        }
    }
</script>

<style scoped>

</style>