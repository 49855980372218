<template>
    <section class="fst-FAQ-item">

        <h3>Что такое кластерный отчет</h3>

        <p><b>Кластеризация</b> - это механизм группировки документов поисковой выдачи по семантической близости их содержания.
            Другими словами, это разделение множества документов поисковой выдачи на подмножества. Например, в
            результате поискового запроса получена большая поисковая выдача (сотни или тысячи документов). Анализировать
            такую выдачу путем просмотра документов достаточно длительно и трудоёмко. Если выполнить кластеризацию, то
            все документы поисковой выдачи будут разделены на группы (кластеры), в которые будут попадать документы
            схожие по содержанию или смыслу. В дальнейшем можно просмотреть перечень групп, на которые разделена
            поисковая выдача и просматривать отдельно документы каждой группы.</p>
        <p>В результате кластеризации формируется отчет, позволяющий визуализировать данные в виде карты кластеров</p>

        <span class="image-wrap"><img src="/clusterMap1.png"/></span>

    </section>
</template>

<script>
    export default {
        name: "ClusterWhatIs",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersStart'
            }
        }
    }
</script>

<style scoped>

</style>