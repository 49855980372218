<template>
    <section class="fst-FAQ-item">

        <h3>Конструктор запроса</h3>

        <p>Для удобства написания сложных запросов, вы можете воспользоваться конструктором поисковых запросов.<br>
            Открыть конструктор можно в меню рядом с поисковой строкой: нажмите на иконку с тремя точками и выберите в выпадающем списке <b>Конструктор запроса</b>.<br>

            <span class="image-wrap" style=""><img src="/qConstructor01.png"></span>
            <span class="image-wrap" style=""><img src="/qConstructor02.png"></span>
            Выберите в выпадающем списке атрибуты по которым необходимо совершить поиск: <b>Заголовок</b>, <b>Абстракт</b>, <b>Компания</b>.<br>
            В поле <b>Ключевые слова</b> укажите поисковый запрос, например: <em>Neural Networks</em>.</p>

        <p>После заполнения вы можете добавить новое поле и выбрать в выпадающем списке один из операторов: <b>AND</b>, <b>OR</b>, <b>NOT</b>. Заполните оставшиеся поля и нажмите на иконку с лупой, чтобы совершить поиск.</p>

        <p>Пример запроса:<br>
            <span class="image-wrap" style=""><img src="/qConstructor03.png"></span></p>

    </section>
</template>

<script>
    export default {
        name: "QueryConstructor",
        data() {
            return {
                section: 'search',
                subSection: 'queryConstructor'
            }
        }
    }
</script>

<style scoped>

</style>