<template>
    <div class="fst-FAQ wrapper">
        <Header></Header>
        <div class="faqWrapper" v-loading="loading">
            <div class="faqLeft">
                <el-tabs class="h-100" type="border-card" v-model="activeTab" @tab-click="handleClickSection">
                    <el-tab-pane v-for="(section, sKey) in sections" :key="sKey"
                                 :label="section.title" :name="sKey">
                        <el-collapse v-model="openedSubSections[sKey]"
                                     @change="val => handleToggleSubSection(val, sKey)">
                            <el-collapse-item v-for="(subSection, ssKey) in section.items" :key="ssKey"
                                              :title="subSection.title" :name="ssKey">
                                <ol class="links">
                                    <li v-for="(link, lKey) in subSection.items"
                                        :key="lKey">
                                        <router-link :to="`/faq/${lKey}`">
                                            {{link.title}}
                                        </router-link>
                                    </li>
                                </ol>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="faqMain">
                <component :is="componentInstance" ref="textComponent"></component>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from "../components/Header";
    import {mapState} from "vuex";

    export default {
        name: "FAQ",
        components: {Header},
        watch: {
            route() {
                this.getComponent();

            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
                section: state => state.faq.section
            }),
            sections() {
                return _.pickBy(this.section, sec => {
                    return !sec.allowed || this.isDev || this.allowed.contains(sec.allowed)
                })
            },
            route() {
                return this.$route.params.alias
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
            isDev() {
                return this.userInfo.roles.indexOf('DEV') >= 0;
            },
        },
        created() {
            this.getComponent();
            setTimeout(() => {
                let _c = this.$refs.textComponent || {};
                this.loading = false;
                this.curData = {
                    section: _c.section,
                    subSection: _c.subSection,
                }
                this.activeTab = _c.section;
                _.forEach(this.sections, (section, sKey) => {
                    this.openedSubSections[sKey] = _c.section === sKey ? [this.curData.subSection] : [Object.keys((section.items) || {})[0]]
                })
                this.openedSubSectionsLast = _.cloneDeep(this.openedSubSections);

            }, 500);
        },
        data() {
            return {
                curData: {},
                openedSubSections: {},
                openedSubSectionsLast: {},
                loading: true,
                activeTab: '',
                componentInstance: () => ({}),
            }
        },
        methods: {
            getComponent() {
                if (this.$route.params.alias) {
                    this.componentInstance = () => import('@/components/faq/' + this.$route.params.alias)
                } else {
                    this.componentInstance = () => import('@/components/faq/SearchOperators')
                }
            },
            handleClickSection(tab) {
                if (this.openedSubSections[tab.name].length === 1) {
                    let path = Object.keys(this.sections[tab.name].items)[0]
                    this.redirect(path, tab.name)
                }
            },
            handleToggleSubSection(val, type) {
                if (val.length > this.openedSubSectionsLast[type].length) {
                    this.redirect(_.last(val), type)
                }
                this.openedSubSectionsLast = _.cloneDeep(this.openedSubSections)
            },
            redirect(path, type) {
                let _path = Object.keys(((this.sections[type].items[path] || {}).items) || {})[0];
                if (_path && _path !== this.$route.params.alias) {
                    this.$router.push({path: _path});
                }
            }
        }

    }
</script>

<style lang="scss">
    .fst-FAQ {

        .faqWrapper {
            background: $iq-color-grey-ultra-light;
            display: flex;
            min-height: 50vh;
            flex: 1;
            padding: 15px;
            gap: 15px;
        }

        .faqLeft {
            flex-basis: 500px;

            .el-tabs {
                max-height: calc(100vh - 115px);
                overflow: auto;
            }

            .el-collapse-item__header {
                font-size: .85rem;
                height: 38px;
                padding-left: 10px;

                &.is-active {
                    background: #f5f7fa;

                }
            }

            .el-collapse-item__content {
                padding: 5px 10px 15px;
            }

            .el-collapse-item {
                &:first-of-type {
                    margin-top: -1px;
                }
            }

            .links {
                padding: 0 0 0 15px;
                margin: 0;

                a {
                    display: block;
                    &.router-link-active {
                        background: #e5effb;
                        padding: 1px 5px;
                        margin: 0 -12px 0 -3px;
                        border-radius: 3px;
                        text-decoration: none;
                        font-weight: 500;
                    }
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }

        .faqMain {
            display: flex;
            flex: 1;

            img {
                max-width: 100%;
                border-radius: 4px;
            }

            ul, ol, p {
                max-width: 850px;

                li {
                    margin: 10px 0;
                }

                &.dashed {
                    margin: 7px 0 7px 15px;

                    > li {
                        margin: 15px 0;
                    }

                    .dashed,
                    &.narrow {
                        > li {
                            margin: 6px 0;
                        }
                    }
                }
            }

            .table-wrap, .image-wrap {
                margin: 15px 0;
                display: block;
            }
        }

        em {
            font-weight: 400;
            font-style: italic;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .figs {
            max-width: 100%;
            font-size: 14px;
            text-align: center;

            td {
                padding: 0 10px;
            }
        }

        .nice_scroll, div, section {
            &::-webkit-scrollbar-track {
                border-radius: 4px;
                background-color: #E5EDF5;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #E5EDF5;
            }


            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #BFC8D3;
            }
        }
    }

    .fst-FAQ-item {
        padding: 10px 15px;
        background: #fff;
        flex: 1;
        max-height: calc(100vh - 135px);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
        overflow: auto;

        h3 {
            margin: 2px 0 10px;
            color: #767B7C;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.15;
            background: #f5f7fa;
            margin: -10px -15px 0 -15px;
            padding: 8px 15px;
            border-bottom: 1px solid #e6e6e6;
        }

    }
</style>