<template>
    <section class="fst-FAQ-item">

        <h3>Настройка полей</h3>

        <p>Настройка полей тепловой карты выполняется в следующей последовательности:</p>
        <ol>
            <li>в выпадающем списке "Источник" необходимо выбрать источник (Новости, Наука и т.д.), для которого будет
                строиться тепловая карта;<br>
                <span class="image-wrap"><img src="/hmSource1.png"/></span>
            </li>
            <li>в выпадающих списках "Строки" и "Колонки" выбрать источники для формирования строк (колонок);<br>
                <span class="image-wrap"><img src="/hmColsRows1.png"/></span>
            </li>
            <li>в выпадающих списках, расположенных рядом с выпадающими списками "Строки" и "Колонки" выбрать конкретные
                значения столбцов и строк.
                <p>Чтобы выбрать сразу все доступные значения, необходимо кликнуть по иконке
                    "Выберите макс. кол-во строк" ("Выберите макс. кол-во колонок"), чтобы удалить все выбранные
                    значения
                    кликнуть иконку в виде крестика (иконки расположены справа от выпадающих списков);</p>
                <span class="image-wrap"><img src="/hmTags1.png"/></span>
            </li>
            <li> нажать кнопку "Рассчитать".
            </li>
        </ol>


        <p class="mt-4">Настройка вида отображения данных на тепловой карте, осуществляется выбором в выпадающем списке "Значения"
            одного из значений:</p>
        <ul class="dashed narrow">
            <li>По строкам (значение по умолчанию);</li>
            <li>По столбцам;</li>
            <li>Абсолютные.</li>
        </ul>

        <span class="image-wrap"><img src="/hmValuesBy1.png"/></span>

        <p><b>По строкам</b> - В данном варианте данные сравниваются построчно. Т.е. наиболее интенсивную заливку в
            строке будут иметь ячейки, у которых больше документов, по сравнению с другими ячейками данной строки. </p>
        <p><b>По столбцам</b> - В данном варианте данные сравниваются по столбцам. Т.е. наиболее интенсивную заливку в
            столбце будут иметь ячейки, у которых больше документов, по сравнению с другими ячейками данного столбца.
        </p>
        <p><b>Абсолютные</b> - В данном варианте данные сравниваются по всей матрице. Т.е. наиболее интенсивную окраску
            будут иметь ячейки, у которых больше всего документов, по сравнению с другими ячейками матрицы.</p>

    </section>
</template>

<script>
    export default {
        name: "HeatMapFields",
        data() {
            return {
                section: 'analytics',
                subSection: 'heatMap'
            }
        }
    }
</script>

<style scoped>

</style>