import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import {actions, mutations} from './mutations'
import systemAlert from './systemAlert'
import faq from './faq'
import quickTour from './QuickTour/quickTour'
import globals from './globals'
import searchWidgets from './searchWidgets'

Vue.use(Vuex);

const store = new Vuex.Store({
    state, mutations, actions,
    modules: {
        systemAlert,
        quickTour,
        faq,
        globals,
        searchWidgets
    }
});

export default store;
