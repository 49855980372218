<template>
    <section class="fst-FAQ-item">
        <h3>Операторы поиска</h3>
        <p>В поисковом запросе допускается использование следующих операторов:</p>
        <ul class="dashed">
            <li>Оператор: <b>AND</b> - Логическое И.<br>
                Запрос <em>Qualcomm AND 5G</em> найдёт документы, в которых есть термин <b>Qualcomm</b> и термин &nbsp;<b>5G</b>. (Термины могут совпадать или не совпадать друг с другом).</li>
            <li>Оператор: <b>OR</b> - Логическое ИЛИ.<br>
                Запрос <em>Qualcomm OR 5G</em> найдет документы, содержащие хотя бы один из терминов <b>Qualcomm</b> или <b>5G</b>. (Термины могут совпадать или не совпадать друг с другом).</li>
            <li>Оператор: <b>NOT</b> - Логическое отрицание.<br>
                Запрос <em>5G NOT Qualcomm</em> найдет документы с термином <b>5G</b> и без термина <b>Qualcomm</b>.</li>
            <li>Оператор: <b>@</b>&nbsp;- Поиск без морфологических преобразований.<br>
                Запрос <em>@question @answering</em> найдет документы, содержащие только комбинации слов, <b>question answering</b>, и не будет расширять набор результатов документами, содержащими комбинации слов, такие как <b>questions answered</b>.</li>
            <li>Оператор: <b>()</b>&nbsp;- Группирование.<br>
                Запрос <em>Rostelecom AND (5G OR 4G)</em> найдет документы с обоими терминами <b>Rostelecom</b> и <b>5G</b> или с обоими терминами <b>Rostelecom</b> и <b>4G</b>.</li>
            <li>Оператор: <b>" "</b>&nbsp;- Сочетание слов.<br>
                Запрос <em>«artificial intelligence»</em> найдет документы, где за словом <b>artificial</b> следует слово <b>intelligence</b>, т.е. эти слова расположены рядом (для сравнения: запрос <em>artificial intelligence</em> найдет документы с обоими этими терминами, но термины будут не обязательно располагаться рядом друг с другом).</li>
            <li>Оператор: <b>*</b> - Поиск по началу слова.<br>
                Запрос <em>pre*</em> найдет документы со словами, начинающимися с «pre», например: <b>pre</b>dict,&nbsp;<b>pre</b>ss,&nbsp;<b>pre</b>requisites.</li>
            <li>Оператор: <b>() DIST N</b> - Поиск на расстоянии не более N слов.<br>
                Запрос <em>(Qualcomm AND 5G) DIST 3</em> найдет документы, в которых термины <b>Qualcomm</b> и <b>5G</b> будут расположены на расстоянии не более 3 слов между ними. Внутри операторов DIST можно использовать более сложные конструкции запроса, например: <b>((Rostelecom OR Qualcomm) AND (5G OR 4G)) DIST 3</b>.</li>
        </ul>
        <p>По умолчанию условия поиска, введенные в поле поиска, объединяются в поисковый запрос с помощью логического «AND» (союза и).</p>

        <p>Пример: поисковый запрос <b>Qualcomm 5G</b> найдет документы, содержащие оба термина <b>Qualcomm</b> и <b>5G</b>.</p>
    </section>
</template>

<script>
    export default {
        name: "SearchOperators",
        data(){
            return{
                section: 'search',
                subSection: 'syntaxSearch'
            }
        }
    }
</script>

<style scoped>

</style>