import { render, staticRenderFns } from "./TechSignalsFiltering.vue?vue&type=template&id=2ba4de80&scoped=true&"
import script from "./TechSignalsFiltering.vue?vue&type=script&lang=js&"
export * from "./TechSignalsFiltering.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba4de80",
  null
  
)

export default component.exports