<template>
    <section class="fst-FAQ-item">

        <h3>Описание полей</h3>

        <p>По умолчанию, данные в интерфейсе "Технологические сигналы" отображаются в табличном виде. Таблица состоит из
            следующих полей:</p>
        <ul class="dashed">
            <li>Ключевое слово;</li>
            <li>Года, выбранного для анализа периода;</li>
            <li>Специфичность;</li>
            <li>Рост за последний полный год;</li>
            <li>Рост средний.</li>
        </ul>

        <span class="image-wrap"><img src="/wsExample1.png"/></span>

        <h4>Ключевое слово</h4>
        <p>Поле "Ключевое слово" содержит перечень ключевых слов. Перечень формируется на основе
            поискового запроса и года окончания выбранного периода. Например, выбран период для анализа 2022 -2024 год,
            поисковый запрос "machine learning". В данном случае, в поле "Ключевое слово" попадут ключевые слова из
            найденных документов за 2024 год по запросу "machine learning". Если поисковый запрос пустой (в строку
            поиска ничего не вводилось), то в поле "Ключевое слово" попадут ключевые слова из найденных документов за
            2024 год. </p>
        <p><em>Примечание</em>.<br> По умолчанию в поисковой строке отображается последний поисковый запрос,
            выполненный в интерфейсе "Поиск". При необходимости его можно изменить или уточнить в строке поиска раздела
            "Технологические сигналы" (ввести в строку поиска новый запрос или удалить полностью запрос и нажать на
            иконку в виде лупы).</p>
        <p>При наведении курсора на конкретное ключевое слово, рядом с курсором появляется иконка в виде знака вопроса
            и спустя некоторое время в отдельном окне появляется динамическая справка из Wikipedia с вариантами определений, данного ключевого слова.</p>
        <span class="image-wrap"><img src="/wsWiki1.png"/></span>

        <h4>Года, выбранного для анализа периода (2022, 2023, 2024 ... )</h4>
        <p>Поля с годами, выбранного для анализа периода содержат информацию о количестве публикаций
            с ключевым словом в данном году. Количество полей зависит от выбранного периода. Чем шире период тем больше
            полей будет отображаться. Поля отображаются с шагом в один год. Клик по значению количества документов,
            открывает в новой вкладке найденные документы за данный год.</p>
        <h4>Специфичность</h4>
        <p>"Специфичность" показывает долю количества документов с данным ключевым словом, найденных в
            результате поиска, среди всех документов с данным ключевым словом. Например, специфичность 0.4 ключевого
            слова "covid 19" для поискового запроса "machine learning" означает, что из всех документов с данным
            ключевым словом, хранящихся в базе, в поисковую выдачу попадут 40% из них (т.е. из всех документов с
            ключевым словом "covid 19", только в 40% документов, упоминается"machine learning").</p>
        <h4>Рост за последний полный год и Рост средний</h4>
        <p>Поля "Рост за последний полный год" и "Рост средний" показывают динамику
            роста количества публикаций с данным ключевым словом за последний полный год и в среднем за выбранный
            период, соответственно.</p>
        <p><em>Примечание</em>.<br> Если в выбранный период входит текущий год, то "Рост за последний полный год"
            и "Рост средний"
            рассчитываются исходя из предполагаемого количества документов, которое будет в конце текущего года.
            Предполагаемое количество документов на конец года рассчитывается методами экстраполяции. При этом в
            визуальном интерфейсе данные по количеству документов за текущий год отображаются фактические. </p>
        <p><em>Пример</em>. <br>
            Анализ технологических сигналов выполняется 01.09.2024. Для анализа выбран период 2022 - 2024 год. Для
            ключевого слова "<em>machine learning</em>" распределение документов по годам следующее:
            <ul class="dashed narrow">
        <li>2022: 42 920,</li>
        <li>2023: 46 734,</li>
        <li>2024: 44 039.</li>
    </ul>
        Средний рост за последний полный год - +8.88%. Значение 8.88%, рассчитано исходя из
            предполагаемого количества документов на конец 2024 года (на 31.12.2024), а в интерфейсе показано
            фактическое количество документов (по состоянию на 01.09.2024).</p>

    </section>
</template>

<script>
    export default {
        name: "TechSignalsFields",
        data() {
            return {
                section: 'analytics',
                subSection: 'techSignals'
            }
        }
    }
</script>

<style scoped>

</style>