<template>
    <section class="fst-FAQ-item">

        <h3>Фильтрация и сортировка</h3>

        <p>Изменение порядка сортировки в поле таблицы осуществляется кликом по его названию. Переключение порядка
            сортировки происходит по кругу (т.е. чтобы выбрать другой вариант сортировки необходимо повторно кликнуть по
            названию столбца).</p>
        <p>Для фильтрации данных в конкретном поле таблицы, необходимо кликнуть на иконку в виде трёх вертикальных
            точек, расположенную в названии поля. Ввести необходимые параметры и нажать "Применить". В полях таблицы, в
            которых выбрана фильтрация, иконка в виде трёх вертикальных точек заменяется на иконку в виде фильтра. Клик
            по ней позволяет уточнить, ранее выбранные параметры фильтрации или полностью очистить фильтр.<br>
        </p>

        <span class="image-wrap"><img src="/wsFilterSign1.png"/></span>

    </section>
</template>

<script>
    export default {
        name: "TechSignalsFiltering",
        data() {
            return {
                section: 'analytics',
                subSection: 'techSignals'
            }
        }
    }
</script>

<style scoped>

</style>