<template>
    <div class="fst-SummarizationBlock" :class="{collapsed: summarization.collapsed}">
        <div class="title">
            <span>{{$t('label.Summarization')}}</span>
            <div class="titleBtns" :class="{disabled: summarization.loading}">
                <i :class="minMaxIcon" @click="handleMinMax"></i>
                <i class="fal fa-times " @click="handleShowSummarization(false)"></i>
            </div>
        </div>

        <div class="text" v-loading="summarization.loading">
            <div v-html="summarization.text"></div>
        </div>

        <el-alert class="dontClose mt-2 " v-if="summarization.loading"
                  type="warning"
                  show-icon
                  :closable="false"
                  :title="$t('label.dontCloseOnSummarization')"></el-alert>


    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "SummarizationBlock",
        data() {
            return {}
        },
        computed: {
            ...mapState({
                summarization: state => state.summarization
            }),
            minMaxIcon() {
                return this.summarization.loading && this.summarization.collapsed ? 'text-primary fa-spinner-third fad fa-spin'
                    : this.summarization.collapsed ? 'fa-window-maximize fal'
                        : 'fa-window-minimize fal'
            }
        },
        methods: {
            handleShowSummarization(state) {
                if (!this.summarization.loading) {
                    this.$store.commit('updateState', [{
                        path: 'summarization.show',
                        data: state,
                    }])
                }
            },
            handleMinMax() {
                if (!this.summarization.loading) {
                    this.$store.commit('updateState', [{
                        path: 'summarization.collapsed',
                        data: !this.summarization.collapsed,
                    }])
                }
            }

        }
    }
</script>

<style scoped lang="scss">
    .titleBtns {
        display: flex;
        gap: 7px;

        i {
            padding: 3px;
            cursor: pointer;
        }
        &.disabled i{
            color: #D0D0D0;
            cursor: not-allowed;
            &.text-primary{
                color: $iq-color-primary;
            }
        }

    }

    .fst-SummarizationBlock {
        width: 818px;
        background: #fff;
        padding: 10px 15px;
        border: $border;

        .title {
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: 7px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: $border;
            padding-bottom: 7px;
        }

        .text {
            transition: all .3s ease;
            font-size: 0.8rem;
            line-height: 1.5;
            min-height: 100px;
            max-height: 250px;
            overflow: hidden auto;
            margin-right: -5px;
            //white-space: pre-line;
            padding: 0 5px;

        }

        &.collapsed {
            .title {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }

            .text {
                min-height: 0;
                max-height: 0;
            }
            .el-alert{
                display: none;
            }
        }

    }
</style>