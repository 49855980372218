export default {
    yes: 'Yes',
    no: 'No',
    error: 'Error',
    warning: 'Warning',
    apply: 'Apply',
    cancel: 'Cancel',
    delete: 'Delete',
    close: 'Close',
    clean: 'Clean',
    clear: 'Clear',
    search: 'Search',
    save: 'Save',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    actions: 'Actions',
    from: 'from',
    to: 'to',
    in: 'in',
    inSource: 'in',
    total: 'Total',
    active: 'Active',
    visible: 'Visible',
    name: 'Name',
    emptyResult: 'Empty result',
    dragFile: 'Drag file here or <em>browse</em>',
    selectFile: 'Select file',
    upgradePlan: 'To open this tab, contact us through our website',
    Favorites: 'Favorites',
    operators: 'operators',
    saveSearch: 'Save search',
    createCluster: 'Query clustering',
    structuredQuery: 'Structured query',
    exportToExcel: 'Export to Excel',
    moreLikeText: 'More Like Text',
    searchIn: 'Search in:',
    allFields: 'All fields',
    title: 'Title',
    titleDescription: 'Title & description',
    allCompanies: 'All',
    inCompanies: 'Companies',
    textSearchOnlyIn: 'Text search in',
    onlyBefore: '',
    onlyAfter: 'only',
    companies: 'Companies',
    mySearch: 'My search:',
    savedSearches: 'Saved searches',
    history: 'History',
    showDocuments: 'Show documents:',
    allDocs: 'All',
    notViewedDocs: 'Not viewed',
    viewedDocs: 'Viewed',
    sendMessage: 'Send message',
    writeYourNotesHere: 'Write your notes here',
    News: "News",
    Science: "Science",
    ScienceCrossref: "Crossref",
    Patents: "Patents",
    Companies: "Companies",
    MarketSize: "Markets",
    Investment: "Investment",
    Investments: "Investments",
    ChooseSource: 'Select the source type to search',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    reset: 'Reset',
    copyToClipboard: {
        title: 'Copy to clipboard',
        success: 'Copying complete'
    },
    label: {
        userMenu: {
            settings: "Notions",
            searchProfile: 'Search profile',
            admin: "Admin panel",
            langEn: "Lang: En",
            langRu: "Lang: Ru",
            logout: "Logout"
        },
        all: 'All',
        searchMode: 'Search',
        clusterMode: 'Clust.Demo',
        clusterModeSrv: 'Clusters',
        analyticsMode: 'Analytics',
        quickTour: 'Quick tour',
        help: 'Knowledge base',
        leaveFeedback: 'Leave feedback',
        filterBy: 'Filter',
        chartCategory: 'Charts',
        feedback: 'Feedback',
        writeMessage: 'Write us a message',
        writeMessagePlaceholder: 'How can we help you, human?',
        notes: 'Notes',
        moreLikeThis: 'More like this',
        citedBy: 'Cited by',
        references: 'References',
        allLinks: "All links",
        publisher: "Publisher",
        translate: 'Translate',
        translateToRu: 'Translate to Ru',
        sorting: 'Sort by',
        sortBy: 'Sort by:',
        standardRelevance: 'Standard relevance',
        semanticRelevance: 'Semantic relevance',
        clusterRelevance: 'Cluster relevance',
        docDate: 'Document date',
        score: 'Rel.score only',
        pattern: 'Pattern:',
        searchType: 'Search type:',
        direct: 'Direct',
        context: 'Context',
        years: 'Years',
        byQuarters: 'by quarters',
        byYears: 'by years',
        year: 'Year',
        byYear: ' year',
        totalInvestments: 'Total investments: $',
        showMore: 'Show more',
        showLess: 'Show less',
        showAll: 'Show all',
        enterNameOfQuery: 'Enter name of query',
        documentType: 'Document type',
        savedSearch: 'Saved search',
        advSearch: 'Advanced search',
        advSearchON: 'Advanced search ON',
        advSearchOFF: 'Advanced search OFF',
        investors: 'Investors',
        searchHistory: 'Search history',
        abstract: 'Abstract',
        description: 'Description',
        investmentTotal: 'Investment Total',
        fullText: 'Full text',
        relatedNews: 'Related news',
        noResult: 'No result',
        authorsCountries: 'Authors\' countries',
        authorsOrgs: 'Authors\' organizations',
        hqCountry: "Headquarter's country",
        authors: 'Authors',
        fundingOrganization: 'Funding organization',
        journalSubjectArea: 'Journal subject area (ASJC)',
        citeScore: 'Journal CiteScore',
        trends: "Trends",
        industries: 'Industries',
        metrics: 'Metrics',
        articleViews: 'Article views',
        accessesFrom: 'Accesses from',
        founded: 'Founded',
        news: "News",
        totalNews: "Total news",
        date: "Date",
        title: "Title",
        source: "Source",
        values: "Values",
        rows: 'Rows',
        columns: 'Columns',
        calculate: 'Calculate',
        colsPlaceholder: 'Column items. Maximum cols',
        rowsPlaceholder: 'Row items. Maximum rows',
        companyRank: 'Company Rank',
        companyStatus: 'Company Status',
        numberOfEmployees: 'Number of employees',
        companyAddress: 'Company Address',
        notSelected: 'Not selected',
        fundedCompany: 'Funded company',
        reportDate: 'Report Date',
        marketSegment: 'Market segment',
        period: 'Period',
        from: 'from',
        to: 'to',
        textSearch: 'text search',
        exportToExcel: 'Export to excel',
        apply: 'Apply',
        showTourOnStart: 'Show Quick Tour on start',
        tags: "Tags",
        backToSearch: 'Standard search',
        enterNewTag: 'Enter new tag...',
        morLikeThis: "More like this document",
        morLikeText: "More like text",
        onlyMine: "Only mine",
        publicNotion: 'Public notion',
        amountRaised: "Amount Raised",
        type: "Type",
        documents: "Documents",
        analytics: "Analytics",
        openInNewTab: "Open in new tab",
        keywords: "Keywords",
        patentFamily: "Patent family",
        patentFamilyHead: "Patent family head",
        patentCountry: "Publication country",
        headCountry: "First country",
        applicationDate: "Application date",
        publicationDate: "Publication date",
        publication: "Publication",
        priorityDate: "Priority date",
        applicant: "Applicant",
        id: "ID",
        currentPatent: 'Current patent',
        customFacetsSeparator: '----- custom facets -----',
        patents: 'Patents',
        applications: 'Applications',
        families: 'Families',
        inventions: "Inventions",
        dataCoverage: "Data coverage",
        trendReport: 'Trends Monitoring Report',
        trendMonitoring: 'Data coverage report',
        checkMaxRows: 'Check max number of rows',
        checkMaxCols: 'Check max number of columns',
        searchFor: 'Search for',
        addAnotherField: 'Add another field',
        filterSign:'Filter significance',
        hideSign:'Hide significance',
        showSign:'Show significance',
        sortDirection: 'Sort direction',
        exportFacets: 'Facets export',
        Summarization: 'Summarization',
        Summarize: 'Summarize',
        dontCloseOnSummarization: "The request is being processed, please do not close the page",

    },
    investments: {
        funding: 'Funding',
        investments: 'Investments',
        acquisitions: 'Acquisitions',
    },
    placeholder: {
        Find_news: "Find news",
        Find_articles: "Find articles",
        Find_patents: "Find patents",
        Find_companies: "Find companies",
        Find_Market_segments: "Find Market segments",
        Find_investment: "Find investment",
        Find_investor: "Find investor",
        Type_query: 'Type query',
        More_like_text: 'Paste text here. Minimum recommended text size is 30 words. The larger the text is – the better the results will be.',
        More_like_text_desc: 'Please, paste the text you want to find similar documents to. Minimum recommended text size is 30 words.',
        Enter_keyword: 'Enter keyword',
        youCanUse: `You can use <b>k</b>, <b>m</b> and <b>b</b> letters. Example: 32k = 32 000`,
        joinByAND: 'Join selected values by AND<br>(find documents, which contain both values)',
        joinByOR: 'Join selected values by OR<br>(find documents, which contain at least one of the values)',
        searchByName: 'Search by name',
    },
    export: {
        all: "Export all (10k max)",
        checked: "Export checked",
    },
    facets: {
        year: "Year",
        invt_year: "Year",
        year_of_patent: "Publication year",
        year_of_application: "Application year",
        patent_family: "Patent family",
        patent_family_head: "Patent family head",
        patent_family_head_only: "Patent family head only",
        documents_with_pdf_only: 'With PDF only',
        head_country: "First country",
        trend: "Trend",
        industry: "Industry",
        applicant: "Applicant",
        company: "Company",
        groups_of_web_sites: "Groups of Web sites",
        web_site: "Web site",
        system_Keywords: "System Keywords",
        source_keywords: "Source keywords",
        query_keywords: "Query keywords",
        country: "Country",
        patentCountry: "Publication country",
        countries: "Countries",
        countries_alignment: 'Country category',
        keywords: "Frequent keywords",
        subject_area: "Subject area",
        subject: "Subject",
        significant_keywords: "Specific keywords",
        companies_significant: "Specific company",
        significant: "Specific",
        significant_low: "specific",
        frequent: "Frequent",
        frequent_low: "frequent",
        journal_quality: "Journal quality",
        funded: "Funded",
        authors: "Authors",
        country_patent_office: "Publication country",
        country_alignment: "Country category",
        patent_class: "Patent class (CPC)",
        patent_class_ext: "Patent class (CPC) EXT",
        cpc_ext: "Patent class (CPC)",
        founded: "Founded",
        category: "Category",
        companyCategory: "Company category",
        total_investments: "Total investments",
        company_size: "Company size",
        company_rank: "Company rank",
        status: "Status",
        type: "Type",
        subtype: "Subtype",
        publications: 'Publications',
        publicationtype: "Publication type",
        publicationsubtype: "Publication subtype",
        investor: "Investor",
        investors: "Investors",
        news: "News",
        funded_company: "Funded company",
        sumDollar: 'Sum, $',
        date: 'Date',
        all: 'All publications',
        withAbstract: 'Only with abstract',
        citedByScienceCount: 'Citation count',
        citedbysciencecount: 'Citation count',
        values: {
            friendly: 'Friendly',
            unfriendly: 'Unfriendly',
        }

    },
    dashboard: {
        view360: '360&deg; view',
        techRating: 'Technology Rating',
        countryRating: 'Country Rating',
        onlyTrends: 'Search trends only',
        heatmap: 'Technology Heatmap',
        patentFirstCountries: 'Publication / First country',
        weakSignals: 'Technology Signals',
        Keywords: 'Keywords',
        Most_active_companies: "Most active companies",
        Venture_investments_dynamics: "Venture investments dynamics",
        MA_dynamics: 'M&A dynamics',
        Top_venture_investors: 'Top venture investors',
        Top_MA_deals: 'Top M&A deals',
        Top_funded_companies: 'Top funded companies',
        Top_research_institutions: 'Top research institutions',
        Top_research_leaders: 'Research leaders',
        Top_patent_applicants: 'Top patent applicants',
        News_dynamics: 'News dynamics',
        Venture_stages: 'Venture stages',
        Markets_evaluation: 'Markets evaluation',
        Total_publications: 'Total publications',
        In_the_last: 'In the last',
        Years: "years",
        Total_patents: 'Total patents',
        Total_companies: 'Total companies',
        Research_leaders: 'Research leaders',
        Patent_leaders: 'Country of publication',
        First_country_leaders: 'First country',
        Top_inventors_by_family: "Top inventors (by family)",
        Company_foundation: 'Company foundation',
        Frequent_companies: "Frequent companies",
        Specific_companies: "Specific companies",
        Frequent_keywords: "Frequent keywords",
        Specific_keywords: "Specific keywords",
        Type: "Type",
        Countries: "Countries",
        Subject_area: "Subject area",
        viewAll: 'View all',
        funding: 'funding',
        or: 'or',
        Science: 'Science',
        Patents: 'Patents',
        s: 'S',
        p: 'P',
        and: '&',
        dynamics: 'dynamics',
        exportToExcel: 'Export to Excel',
        totalDollar: 'Total, $',
        dealCount: 'Deal count',
        acquiredBy: 'acquired by',
        for: 'for',
        in: 'in',
        count: 'Count',
        sum: 'Sum',
        compareTo: 'Compare to',
        Absolute: 'Absolute',
        CAGR: 'CAGR',
        mean: 'Mean',
        Arithmetic: 'Arithmetic',
        Geometric: 'Geometric',
        yearChangedTo: 'Year  changed to ',
        yearRestoredTo: 'Year  restored to ',
        hotInRow: "Hot in row",
        hotInColumn: "Hot in column",
        hotAbsolute: "Hot absolute",
        articleDynamics: "Publication dynamics",
        patentDynamics: "Patent dynamics",
        devDynamics: "Development dynamics",
        inventionDynamics: "Invention dynamics",
        weakSignalsTab: {
            keyword: "Keyword",
            significance: 'Significance',
            growthLastYear: 'Growth last full year',
            growthLastYearNoBr: 'Growth last full year',
            growth: 'Growth avg.',
        }
    },
    hints: {
        Patent_leaders: "The patent office where the patent was issued. <br>" +
            "Not necessarily the country where the invention was created or first registered",
        First_country_leaders: "Distribution of  patents by country of first registered",
        Invention_dynamics: 'The chart shows the real years of creation of inventions <br>(filing applications for the first patents in families)',
        Top_inventors_by_family: "The distribution of patents by country of their first registration. <br>" +
            "Only the first patents in families are taken into account - the invention itself",
        HorizontalScroll: 'Press and hold CTRL to enable horizontal wheel scrolling of the Heatmap',
    },
    quickTour: {
        // next: 'Next',
        // back: 'Back',
        // skip: 'Skip tour',
        // start: 'Go on!',
        // finish: 'Finish tour',
        // again: 'Restart tour',
        next: 'Дальше',
        back: 'Назад',
        skip: 'Пропустить',
        start: 'Приступим!',
        finish: 'Завершить тур',
        again: 'Пройти заново',
    },
    notes: {
        abstractNotAvailableFor: 'Abstract not available for ',
        abstractOfCorrespondingDocument: 'Abstract of corresponding document: '
    },
    errors: {
        clusteringNotEnoughDocs: "The minimum number of documents for successful clustering is ",
    },
    auth: {
        errorTitle: 'User is not authorised',
        errorMessage: 'Proceed to <a href="login">authorization page</a>. ',
        login: 'User name',
        pass: 'Password',
    },
    summarization:{
        errorTitle: 'Summarization error'
    },

    main: {
        title: 'Teqviser',
    },
    admin: {
        title: 'Admin panel',
        users: {
            title: 'Users',
            search: 'Search by name/login',
            add: 'Add user',
            statusTitle: 'User status',
            requestList: 'Request list',
            status: {
                ENABLED: 'Active',
                LOCKED: 'Blocked'
            },
            table: {
                empty: 'Users not found',
                name: 'Name',
                login: 'Login',
                dateAccountExpire: 'Expiration date'
            },
            deleteUsers: {
                sure: 'User will be deleted. Continue?',
                deleted: 'User terminated',
            },
            editUser: {
                title: 'Add/Edit user',
                login: 'Login',
                password1: 'Password',
                password2: 'Repeat password',
                fio: 'Name',
                email: 'e-mail',
                groups: 'Groups',
                success: 'User saved',
                dateAccountExpire: 'Account expiration date'
            },
            generateAuthToken: {
                title: 'Auth token generation',
                token: 'Token',
                form: {
                    username: 'User'
                },
                msg: {
                    // generate: 'Будет создан токен аутентификации, для доступа к API пользователя <b>{name}</b>.',
                    generate: 'Auth token for API user <b>{name}</b> will be created.',
                    // headerName: 'Токен нужно посылать в заголовке запроса {name}',
                    headerName: 'Token should be send in request {name} header',
                    successMessage: 'Token has been copied to the clipboard'
                }
            },
            resetAuthTokens: {
                title: 'Reset auth tokens',
                msg: {
                    // confirm: 'Будут сброшены токены аутентификации, для доступа к API пользователя <b>{name}</b>. Продолжить операцию?'
                    confirm: 'Auth tokens for user <b>{name}</b> will be reset. Continue?'
                }
            }
        },
        reqList: {
            regDate: 'Reg. date',
            name: 'Name',
            "email": "E-mail",
            "companyName": "Company",
            "title": "Title",
            "phone": "Phone",
            "comment": "Comment",
            "status": "Status",
            approve: 'Approve',
            decline: 'Decline',
            approved: 'approved',
            declined: 'declined',
            pending: 'pending',
            reqStatus: 'Request status',
        },
        groups: {
            title: 'User groups',
            add: 'Add group',
            table: {
                empty: 'Groups not found',
                name: 'Name',
            },
            deleteGroups: {
                sure: 'Group will be deleted. Continue?',
                deleted: 'Group has been deleted',
            },
            editGroup: {
                title: 'Add/Edit Group',
                name: 'Name',
                roles: 'Roles',
                users: 'Users',
                success: 'Group saved'
            },
        },
        notions: {
            title: 'Notions',
            addNotion: 'Add notion',
            updateDeleteTags: "Update/delete document tags",
            total: 'Total:',
            table: {
                id: 'ID',
                name: 'Name',
                querySciencePatents: 'Query (Science и Patents)',
                queryNewsCompanies: 'Query (News и Companies)',
                attributeQuery: "Attribute query",
                description: 'Description',
                notion: 'Notion',
                dateCreate: 'Created',
                dateUpdate: 'Updated',
                creator: 'Author',
                icon: 'Icon',
                selectIcon: 'Select an icon',
                visibleByCompany: "Visible by company",
            },
            filter: {
                name: 'Title',
                placeholder: 'Search by Title, Query, Description and Author'
            },
            editor: {
                title: 'Create notion | Edit notion',
                form: {
                    name: 'Name',
                    querySciencePatents: 'Query (Science и Patents)',
                    queryNewsCompanies: 'Query (News и Companies)',
                    description: 'Description',
                    icon: 'Icon',
                    dateCreate: 'Creation date',
                    dateUpdate: 'Update date',
                    creator: 'Author'
                },
                msg: {
                    success: 'Notion has been saved'
                }
            },
            msg: {
                delete: {
                    confirm: 'Notion will be deleted. Continue?',
                    success: 'Notion has been deleted'
                }
            }
        },
        wordStat: {
            title: 'Word statistics',
            wordPhrase: 'Word / Phrase',
            score: 'Score',
            morphologyFor: "Morphology for",
            semanticProximityFor: "Semantic proximity for",
            description: `<p>Module "Word Statistics" allows you to enrich your notions by searching for semantically
                        similar*
                        words and combinations of words extracted from the texts in Teqviser.
                        Since science/patent vocabulary differs from news vocabulary, two search options are
                        provided:</p>
                    <ul class="mt-0 mb-2">
                        <li>Science – search for words and word combinations derived from science papers and patents.
                        </li>
                        <li>News – search for words and word combinations derived from news.</li>
                    </ul>

                    <p class="small">* Semantic similarity - likeness of meaning or semantic context of
                        words/combinations of
                        words.</p>`,
        },
        userEvents: {
            title: 'User events'
        },
        sources: {
            title: 'Web sites'
        }
    },
    searchMode: {
        source: {
            All: "360&deg; view",
            News: "News",
            JustScience: "Science",
            Science: "Science Pro",
            ScienceCrossref: "Crossref",
            ScienceOpenAlex: "OpenAlex",
            ScienceOpen: "Science Open",
            Patents: "Patents",
            Companies: "Companies",
            MarketSize: "Markets",
            Investment: "Investment",
            InvestmentAndCompanies: "Investments and Companies",
            RuNews: "News RU",

        }
    },
    notion: {
        note: {
            filled: 'Concepts, which include ',
            empty: 'Concepts, consisting of variety of terms'
        }
    },
    dataCoverage: {
        docCount: 'Total number of documents',
        docPeriod: 'Period of documents provided',
        lastUpdate: 'Last update date',
        updateFrequency: 'Data update frequency',
        countryDistribution: 'Distribution of data coverage by country in %',
        companyCount: 'Number of companies',
        companyCountryDistribution: 'Distribution of investments by country of companies in which funds are invested',
        investmentCount: 'Total number of transactions',
        investmentCountryDistribution: 'Distribution of data coverage by company country',
        investmentSum: 'Total investment volume',
        docPeriodPatents: 'Patent registration submission period',
        countryDistributionPatents: 'Distribution of patent publication countries in %',
        countryDistributionInvestment: 'Distribution of investments by country of companies in which funds are invested',
        description: {
            news: "Data source - CNET, TechCrunch, Eurekalert, Reuters, Engadjet.",
            science: "The source of the data is one of the four largest scientific publishers in the world, publishing annually about a quarter of all articles published in scientific journals worldwide.",
            scienceOpen: "Data source - Springer, Onepetro.",
            scienceCrossref: "Data source - Crossref.",
            patents: "The collection of patent data is mainly carried out from the EPO database. Patent information is a commercial view of technology.",
            investment: "The data source is the largest foreign resource that contains information on venture capital investments with a database on startups and investors, as well as market research results.",
        },
    },
    dataReport: {
        trends: {
            title: 'Trend report',
        }
    },
    structuredQueryLabel: {
        'title': 'Title',
        'description': 'Abstract',
        'cpcs_code': 'Patent class',
        'companies': 'Company',
        'fullText': 'Full text',
    },
    chatBot:{
        title: 'ChatBot',
        prompt: 'Prompt',
        topic: 'Topic',
        technology: 'Technology',
        role: 'Role',
        company: 'Company',
        industry: 'Industry',
        insertQuery: 'Insert Query',
        chatResponse: 'Chat Response',
        createQuery: 'Create query',
    },


};
