<template>
    <section class="fst-FAQ-item">

        <h3>Рейтинг стран</h3>

        <p>В разделе "<b>Рейтинг  стран</b>" отображаются аналитические данные о вовлеченности различных стран в развитие
            технологий. Интерфейс и функциональные возможности практически аналогичны интерфейсу и функциональным
            возможностям раздела "<router-link :to="`/faq/TechRatingDesc`">Технологический рейтинг</router-link>".</p>
        <p>Отличия:</p>
        <ul class="dashed narrow">

            <li>в рейтинге отображается список стран мира;</li>
            <li>интерфейс дополнен чекбоксом "Искать только тренды" (по умолчанию включён). Во включенном состоянии в
                отчет будут попадать только документы, на которых есть хотя бы один проставленный тренд.
            </li>
        </ul>
        <span class="image-wrap"><img src="/countryRatingDesc1.png"/></span>

    </section>
</template>

<script>
    export default {
        name: "CountryRatingDesc",
        data() {
            return {
                section: 'analytics',
                subSection: 'countryRating'
            }
        }
    }
</script>

<style scoped>

</style>