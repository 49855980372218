<template>
    <section class="fst-FAQ-item">

        <h3>Технологический рейтинг</h3>

        <p>В данном разделе отображается рейтинг технологий в выбранном периоде. Рейтинг отображается
            в виде списка названий технологий. Список технологий формируется на основе выбранного периода, выбранных
            источников (новости, патенты и т.д.) и поискового запроса. Например, если выбрать период с 2022 по 2024 год,
            выполнить поиск без запроса (в строку поиска ничего не вводить), выполнить поиск по всем доступным
            источникам данных (включено по умолчанию), то в список технологий попадут технологии из документов найденных
            за период с 2022 по 2024 год, во всех источниках. Выбор (исключение) источника осуществляется нажатием на
            кнопку с его названием (кнопки исключенных из поиска источников имеет серый фон).</p>

        <span class="image-wrap"><img src="/tRatingSources.png"/></span>

        <p>Данные о рейтинге могут отображаться в двух форматах:</p>
        <ul class="dashed narrow">
            <li>абсолютные (формат по умолчанию);</li>
            <li>CAGR.</li>
        </ul>


        <p>Выбор формата отображения рейтинга осуществляется нажатием на кнопку с соответствующим названием.</p>
        <span class="image-wrap"><img src="/tRatingAbsolute.png"/></span>


    </section>
</template>

<script>
    export default {
        name: "TechSignalsDesc",
        data() {
            return {
                section: 'analytics',
                subSection: 'techSignals'
            }
        }
    }
</script>

<style scoped>

</style>