<template>
    <section class="fst-FAQ-item">

        <h3>Индикатор цвета</h3>

        <p>Индикатор цвета на тепловой карте показывает, как много документов, найдено для конкретной ячейки
            матрицы.</p>
        <span class="image-wrap" style=""><img src="/hmColors.png"></span>

        <p>Чем больше документов, тем интенсивнее будет заливка ячейки.</p>
        <p>При наведении курсора на ячейку появляется всплывающая подсказка, которая содержит информацию о количестве
            документов. Клик по ячейке, открывает в новой вкладке, документы данной ячейки матрицы.</p>

    </section>
</template>

<script>
    export default {
        name: "HeatMapColorMap",
        data() {
            return {
                section: 'analytics',
                subSection: 'heatMap'
            }
        }
    }
</script>

<style scoped>

</style>