<template>
    <section class="fst-FAQ-item">
        <h3>Морфология</h3>
        <p>Поиск осуществляется с учетом морфологической формы слов.</p>

        <p>Пример: Поисковый запрос <em>machine</em> вернет документы, содержащие хотя бы одно из слов <b>machine, machines, machining</b> и т. д.</p>

        <p>При использовании оператора <b>@</b> поиск осуществляется  без морфологических преобразований.      </p>
        <p>Пример: Запрос <em>@question @answering</em> найдет документы, содержащие только комбинации слов, <b>question answering</b>, и не будет расширять набор результатов документами, содержащими комбинации слов, такие как <b>questions answered</b></p>
    </section>
</template>

<script>
    export default {
        name: "Morphology",
        data(){
            return{
                section: 'search',
                subSection: 'syntaxSearch'
            }
        }
    }
</script>

<style scoped>

</style>