<template>
    <section class="fst-FAQ-item">

        <h3>Технологические сигналы</h3>

        <p>В данном разделе отображается изменение динамики упоминания ключевых слов в
            публикациях, в выбранном пользователем периоде. Анализируя эти данные, можно выявить технологии (тренды,
            направления и т.д.), которые демонстрируют рост (спад) интереса к ним.</p>
        <p>Например, ключевые слова "<em>machine learning</em>" и "<em>covid 19</em>",
            анализируемый период 2022 - 2024 год. Средний рост "<em>machine learning</em>" +13.5%, а
            "<em>covid 19</em>" -48.1%. На основе этих данных можно предположить, что в период с 2022 по 2024 год растёт интерес к
            технологии "machine learning", а к "covid 19" в период с 2022 по 2024 интерес постепенно снижается.</p>

        <span class="image-wrap"><img src="/wsExample1.png"/></span>
    </section>
</template>

<script>
    export default {
        name: "TechRatingDesc",
        data() {
            return {
                section: 'analytics',
                subSection: 'techRating'
            }
        }
    }
</script>

<style scoped>

</style>