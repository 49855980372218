<template>
    <section class="fst-FAQ-item">

        <h3>Рейтинг в формате "Абсолютные"</h3>

        <p>В данном формате рейтинг строиться на основе рассчитанного специальным алгоритмом числового значения и
            сравнивается с рейтингом предыдущих лет (показывает рейтинг технологии в выбранном периоде и изменение
            позиции в
            рейтинге по сравнению с предыдущими годами (на сколько позиций поднялась или опустилась технология)). </p>
        <p>Рейтинг отображается в виде списка названий технологий, в котором для каждой технологии отображается:</p>
        <ul class="dashed narrow">
            <li>гистограмма, на которой отображается вклад каждого источника в рассчитанное числовое значение
                технологии;
            </li>
            <li>рассчитанное числовое значение технологии;</li>
            <li>изменение позиции по сравнению с предыдущими годами (периодом сравнения).</li>
        </ul>


        <p>Период сравнения выбирается в выпадающем списке "Сравнение с".<br>Сравнивать можно с предыдущим периодом в 1,
            2 и 3 года.</p>
        <span class="image-wrap"><img src="/tRatingCompareTo.png"/></span>

        <p>Например, для анализа выбран период с 2022 по 2024 год. Для данного периода будут доступны для сравнения
            следующие периоды:</p>
        <ul class="dashed narrow">
            <li>1 years (2021) - период сравнения 01.01.2021 - 01.01.2022;</li>
            <li>2 years (2020) - период сравнения 01.01.2020 - 01.01.2022;</li>
            <li>3 years (2019) - период сравнения 01.01.2019 - 01.01.2022.</li>
        </ul>


        <p>Индикация изменений позиций в рейтинге по сравнению с предыдущими годами:</p>
        <ul class="dashed narrow">
            <li>значение зелёного цвета - технология поднялась в рейтинге по сравнению с периодом сравнения (количество
                позиций, на которое поднялась технология равно указанному значению);
            </li>
            <li>значение красного цвета - технология опустилась в рейтинге по сравнению с периодом сравнения (количество
                позиций, на которое опустилась технология равно указанному значению);
            </li>
            <li>значение серого цвета - позиция технологии в рейтинге по сравнению с периодом сравнения не изменилась.
            </li>
        </ul>


        <h4>Операции с рейтингом:</h4>
        <ol>
            <li>Поиск технологии. Над списком с названием технологий расположена текстовое поле, для поиска необходимой
                технологии по названию.
                <span class="image-wrap"><img src="/tRatingSearch.png"/></span>

            </li>
            <li>Просмотр документов. Для просмотра документов необходимо кликнуть по области источника на гистограмме (в
                новой вкладке откроются документы выбранного источника и технологии).
                <span class="image-wrap"><img src="/tRatingBars.png"/></span>

            </li>
            <li>Сортировка. Выбор направления сортировки осуществляется кликом по иконкам в виде стрелки вверх и
                вниз.
                <span class="image-wrap"><img src="/tRatingSorting.png"/></span>

            </li>
            <li>Обновление рейтинга. Рейтинг обновляется автоматически при изменении:
                <ul class="dashed narrow">
                    <li>поискового запроса;</li>
                    <li>изменении источников, на основе которых строиться рейтинг (включение или исключение науки,
                        патентов и т.д.);
                    </li>
                    <li>анализируемого периода;</li>
                    <li>периода сравнения.</li>
                </ul>
                <p><em>Примечание</em> - при изменении только периода сравнения, в рейтинге, как правило, изменяется
                    только последнее
                    значение в строках рейтинга (изменение позиции по сравнению с периодом сравнения).</p>
            </li>
        </ol>
    </section>
</template>

<script>
    export default {
        name: "TechRatingFormatAbs",
        data() {
            return {
                section: 'analytics',
                subSection: 'techRating'
            }
        }
    }
</script>

<style scoped>

</style>