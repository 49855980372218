<template>
    <section class="fst-FAQ-item">

        <h3>Рейтинг в формате "CAGR"</h3>

        <p>В данном формате рейтинг строиться на основе среднего роста (падения) числа публикаций.<br>
            Сравнивается общее количество публикаций (с упоминанием технологии) в начале и в конце периода.</p>
        <p>Например, период для анализа
            2022&nbsp;-&nbsp;2023 год. Технология <b>Digital twin</b>
            показывает средний рост 28%. Это означает, что за период с 2022 по 2023
            год количество публикаций по данной технологии увеличилось в среднем на 28%.</p>
        <span class="image-wrap"><img src="/tRatingNurbs.png"/></span>

        <p>Рейтинг отображается в виде списка названий технологий, в котором для каждой технологии отображается:</p>
        <ul class="dashed narrow">
            <li>гистограмма, на которой отображается вклад каждого источника в итоговое значение роста (падения) числа
                публикаций;
            </li>
            <li>средний рост (падение) числа публикаций в выбранном периоде.</li>
        </ul>


        <h4>Операции с рейтингом:</h4>

        <ol>
            <li>Поиск технологии. Аналогично рейтингу в формате "Абсолютные".</li>
            <li>Просмотр документов. Аналогично рейтингу в формате "Абсолютные".</li>
            <li>Сортировка. Аналогично рейтингу в формате "Абсолютные".</li>
            <li>Обновление рейтинга. Рейтинг обновляется автоматически при изменении:

                <ul class="dashed narrow">
                    <li>поискового запроса;</li>
                    <li>изменении источников, на основе которых строиться рейтинг (включение или исключение науки,
                        патентов и т.д.);
                    </li>
                    <li>анализируемого периода;</li>
                    <li>формата отображения значения среднего роста (падения) количества публикаций (среднее
                        арифметическое или среднее геометрическое (выбирается в выпадающем списке "Среднее"))
                    </li>
                </ul>
            </li>
        </ol>


    </section>
</template>

<script>
    export default {
        name: "TechRatingFormatCAGR",
        data() {
            return {
                section: 'analytics',
                subSection: 'techRating'
            }
        }
    }
</script>

<style scoped>

</style>