<template>
    <section class="fst-FAQ-item">
        <h3>Атрибуты для поиска</h3>

        <p>Поиск по отдельным атрибутам документов возможен в режиме "Расширенный поиск", который включается тумблером в поисковой строке:</p>

        <p><span class="image-wrap" style=""><img src="/advSearch1.png"></span></p>

        <p>Поиск можно осуществлять по следующим атрибутам:</p>

        <div class="table-wrap">
            <table class="table"><tbody>
            <tr>
                <th>Название атрибута</th>
                <th>Описание атрибута</th>
                <th>Примеры простых запросов</th>
            </tr>
            <tr>
                <td colspan="3"><b>Все типы источников</b></td>
            </tr>
            <tr>
                <td>title</td>
                <td>Название документа</td>
                <td>title: ("price structure" OR "dynamic pricing")</td>
            </tr>
            <tr>
                <td>description</td>
                <td>Основной текст документа</td>
                <td>description: ("price structure" AND retail*)</td>
            </tr>
            <tr>
                <td colspan="3"><br><b>Научные статьи</b></td>
            </tr>
            <tr>
                <td>companies</td>
                <td>Институты, научные организации, компании, с которыми аффилированы авторы научных статей</td>
                <td>companies: ("China institute")</td>
            </tr>
            <tr>
                <td>systemKeywords</td>
                <td>Ключевые слова, которыми протэгированы научные статьи</td>
                <td>systemKeywords: ("artificial intelligence")</td>
            </tr>
            <tr>
                <td>grantCompanies</td>
                <td>Организации, предоставившие грант на исследования, в результате которых написана научная статья</td>
                <td>grantCompanies: ("russian foundation")</td>
            </tr>
            <tr>
                <td>asjc.code</td>
                <td>Детальный код тематики (Subject) научной статьи.<br>

                    <a href="https://app.teqviser.ru/docs/Classification_asjc.xlsx" class="d-block my-2 external-link" target="_blank" rel="nofollow noopener" title="Пройти по ссылке">Скачать список тематик и их кодов</a>

                    Для поиска по верхнеуровневой предметной области (коды, оканчивающиеся на «00») рекомендуется использовать поиск по первым двум цифрам её кода.</td>
                <td>Поиск по коду «2205» выглядит так:<br>
                    asjc.code: 2205<br>
                    &nbsp;<br>
                    Поиск по всем кодам, начинающимся с «22» выглядит так:<br>
                    asjc.code: 22*</td>
            </tr>
            <tr>
                <td colspan="3"><br><b>Патенты</b></td>
            </tr>
            <tr>
                <td>companies</td>
                <td>Заявители патента (applicants)</td>
                <td>сompanies: (gazprom OR rosneft OR lukoil)</td>
            </tr>
            <tr>
                <td>systemKeywords</td>
                <td>Ключевые слова, которыми протэгированы патенты</td>
                <td>systemKeywords: ("operating system")</td>
            </tr>
            <tr>
                <td>cpcs.code</td>
                <td>Код CPC-классификатора патента.Посмотреть классификатор можно здесь:&nbsp;<a href="https://worldwide.espacenet.com/patent/cpc-browser" class="external-link" target="_blank" rel="nofollow noopener" title="Пройти по ссылке">worldwide.espacenet.com/patent/cpc-browser</a></td>
                <td>Поиск всех патентов по производству железа и стали с кодом, начинающимся на C21B:cpcs.code: C21B*</td>
            </tr>
            <tr>
                <td colspan="3"><br><b>Новости</b></td>
            </tr>
            <tr>
                <td>companies</td>
                <td>Компании, упоминаемые в новости</td>
                <td>companies: (Google AND Amazon)</td>
            </tr>
            <tr>
                <td>systemKeywords</td>
                <td>Ключевые слова, которыми протэгированы новости</td>
                <td>systemKeywords: ("carbon steel")</td>
            </tr>
            </tbody></table>
        </div>


    </section>
</template>

<script>
    export default {
        name: "SearchAttributes",
        data(){
            return{
                section: 'search',
                subSection: 'attributeSearch'
            }
        }
    }
</script>

<style scoped>

</style>