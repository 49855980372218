<template>
    <section class="fst-FAQ-item">

        <h3>Описание виджетов</h3>
        <ul class="dashed">
            <li><strong>Keywords<br></strong>Виджет отображает ключевые слова, которые содержаться в научных публикациях
                отвечающим на поисковый запрос. Вы можете выбрать два варианта ранжирования:
                <ul class="dashed">
                    <li>Frequent &ndash; по
                        количеству документов содержащих ключевое слово;
                    </li>
                    <li>Specific &ndash; по специфичности к поисковому
                        запросу (главным образом, будут выделены ключевые слова, которые содержаться в документах
                        отвечающим на
                        поисковый запрос и реже содержаться в других документах);
                    </li>
                </ul>
                <p>Нажмите на ключевое слово, чтобы открыть
                вкладку <strong>Наука</strong> с примененными поисковым запросом
                и фильтром по выбранному ключевому слову.</p>
            </li>
            <li><strong>Most active companies<br></strong>На виджете представлены ТОП наиболее известных и активных в
                масс-медиа компаний.
            </li>
            <li><strong>Publication dynamics<br></strong>График отображает динамику научных публикаций.</li>
            <li><strong>Patent dynamics<br></strong>График отображает динамику патентования.</li>
            <li><strong>News dynamics<br></strong>График отображает динамику новостных статей.</li>
            <li><strong>Venture stages<br></strong>График отображает динамику инвестиций с учетом стадий инвестирования.
            </li>
            <li><strong>Markets evaluation<br></strong>График отображает оценку будущего развития ТОП-5 сегментов рынка
                наиболее близких к поисковому запросу.
            </li>
            <li><strong>Top venture invest</strong><strong>or</strong><strong>s<br></strong>На виджете представлены ТОП
                компаний по сумме инвестиций за выбранный период отвечающим на поисковый запрос.
            </li>
            <li><strong>Venture investments dynamics<br></strong>График отображает динамику венчурных инвестиций исходя
                из количества и суммы инвестиций.
            </li>
            <li><strong>M&amp;A dynamics<br></strong>График отображает динамику сделок M&amp;A.</li>
            <li><strong>Top M&amp;A deals<br></strong>Виджет отображает ТОП M&amp;A сделок исходя из суммы сделок.</li>
            <li><strong>Top funded companies<br></strong>Виджет отображает ТОП инвестируемых компаний исходя из суммы
                инвестиций.
            </li>
            <li><strong>Research leaders<br></strong>Виджет отображает ТОП стран по научным исследованиям исходя из
                количества научных публикаций.
            </li>
            <li><strong>Country of publication<br></strong>Виджет отображает ТОП стран по количеству патентов.</li>
            <li><strong>Top research Institutions<br></strong>Виджет отображает ТОП исследовательских организаций исходя
                из количества научных публикаций.
            </li>
            <li><strong>Top patent applicants<br></strong>Виджет отображает ТОП изобретателей по количеству патентов.
            </li>
            <li><strong>Company category<br></strong>Виджет отображает категории компаний, к которым относятся компании,
                отвечающим на поисковый запрос. Вы можете выбрать два варианта ранжирования:
                <ul class="dashed">
                    <li>Frequent &ndash; по
                        количеству компаний относящихся к категории;
                    </li>
                    <li>Specific &ndash; по специфичности к поисковому запросу
                        (главным образом, будут выделены категории компаний, к которым относятся компании, отвечающим на
                        поисковый запрос и к которым реже относятся другие&nbsp; компании);
                    </li>
                </ul>
                Нажмите на ключевое слово, чтобы
                открыть вкладку <strong>Science</strong><strong> </strong><strong>Pro</strong> с примененными поисковым
                запросом и фильтром по выбранному ключевому слову.
            </li>
            <li><strong>Headquarter's country<br></strong>На виджете представлены ТОП стран по количеству головных
                офисов компаний.
            </li>
            <li><strong>Company foundation<br></strong>График отображает динамику основания компаний за выбранный
                период.
            </li>
        </ul>
        <p>&nbsp;</p>
    </section>
</template>

<script>
    export default {
        name: "View360Start",
        data() {
            return {
                section: 'search',
                subSection: 'view360'
            }
        }
    }
</script>

<style scoped>

</style>