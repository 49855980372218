<template>
    <section class="fst-FAQ-item">

        <h3>Фильтры поиска</h3>

        <p>Для удобства работы с поисковой выдачей, вы можете использовать фильтры с левой стороны экрана. Чтобы
            применить фильтр поставьте галочку в чекбоксе выбранного фильтра и нажмите <b>Apply</b>. Поисковая выдача
            обновится с учетом применимого фильтра. Вы также можете одновременно применять несколько фильтров.</p>

        <span class="image-wrap" style=""><img src="/facets1.png"></span>

        <p>На некоторых вкладках в конце списка фильтров также доступен поиск по <b>Автору, Изданию, ID</b> и <b>URL</b>-адресу.
            Для работы напишите известный вам атрибут в поисковой строке и нажмите на иконку лупы.</p>
        <p>Вы также можете совершить поиск по тексту, если хотите найти конкретный документ. Для этого откройте
            дополнительные инструменты поиска, нажав на иконку с тремя точками и выберите <b>More Like Text</b>.</p>


        <p>Рядом с названием фильтра указывается сумма документов/компаний подходящих под фильтр, если вам нужно скачать
            эти значения, нажмите на иконку файла рядом с <b>Фильтр</b>, в открывшемся окне выберите фильтры по которым
            вы хотите получите значения и нажмите <b>Export to Excel</b>.</p>
    </section>
</template>

<script>
    export default {
        name: "SearchFacets",
        data() {
            return {
                section: 'search',
                subSection: 'searchResult'
            }
        }
    }
</script>

<style scoped>

</style>