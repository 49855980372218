<template>
    <section class="fst-FAQ-item">

        <h3>Режим диаграммы</h3>

        <p>Для перехода в режим инфографика необходимо кликнуть иконку изменения режима отображения:</p>
        <span class="image-wrap"><img src="/showWSDiargam1.png"/></span>

        <p>В режиме диаграммы технологические сигналы отображаются в виде кругов с заливкой разного цвета прилегающих
            друг другу. </p>
        <span class="image-wrap"><img src="/wsDiagram1.png"/></span>

        <p>Размер круга зависит от количества документов. При наведении курсора на круг появляется всплывающая подсказка
            содержащая справочную информацию (год, количество документов и т.д.). Под кругами расположены:</p>
        <ul class="dashed narrow">
            <li>шкала специфичности (при наведении на нее курсора появляется числовое значение специфичности);</li>
            <li>название технологического сигнала (клик по названию открывает окно с определением данного
                технологического сигнала).
            </li>
        </ul>


        <p>При необходимости можно скрыть отображение шкалы специфичности или настроить фильтрацию по данному параметру.
            Для скрытия отображения шкалы специфичности необходимо кликнуть иконку в виде перечеркнутого глаза в строке
            меню. Для фильтрации по специфичности необходимо нажать на иконку в виде фильтра в строке меню, ввести
            необходимые параметры и нажать применить.</p>

        <span class="image-wrap"><img src="/wsFilters1.png"/></span>

        <p>Доступна возможность настройки отображения технологических сигналов. Для этого в
            выпадающем списке, расположенном в строке меню, выбрать параметр по которому будет выполняться
            сортировка.</p>
        <span class="image-wrap"><img src="/wsSort1.png"/></span>

        <p>Доступны следующие значения:</p>
        <ul class="dashe narrow">
            <li>ключевое слово - сортировка будет выполняться по названию технологического сигнала (по алфавиту);</li>
            <li>значение года - сортировка будет выполняться по количеству документов с данным технологическим сигналом,
                в выбранном году (например, если анализируемый период 2022 - 2024 год и выбрать значение для сортировки
                2023 год, то технологические сигналы будут отсортированы по количеству документов с данным
                технологическим сигналом в 2023 году);
            </li>
            <li>специфичность - сортировка будет выполняться по значению специфичности;</li>
            <li>рост по последний полный год - сортировка по значению роста за последний полный год;</li>
            <li>рост средний - сортировка по значению среднего роста.</li>
        </ul>


        <p>Направление сортировка выбирается кликом по иконке в виде стрелки, направленной вверх или вниз, расположенной
            рядом с выпадающим списком выбора параметра сортировки.</p>


    </section>
</template>

<script>
    export default {
        name: "TechSignalsFiltering",
        data() {
            return {
                section: 'analytics',
                subSection: 'techSignals'
            }
        }
    }
</script>

<style scoped>

</style>