<template>
    <section class="fst-FAQ-item">

        <h3>Полнотекстовый поиск в атрибуте и поиск на точное соответствие</h3>

        <p>Возможны три варианта написания запроса к
            атрибутам&nbsp;<b>systemKeywords</b>,&nbsp;<b>companies</b>,&nbsp;<b>grantCompanies</b>:</p>
        <ol>
            <li>
                Для полнотекстового поиска по содержимому атрибута: поиск на вхождение подстроки, поиск с помощью
                операторов <b>OR</b>,&nbsp;<b>AND</b>,&nbsp;<b>NOT</b>&nbsp;и т.д.

                <p>Вид такого запроса:<br>
                    <b>Название_атрибута: (полнотекстовый_запрос_к_атрибуту)</b></p>
                    <p>Пример:<br>
                    <em>systemKeywords: ("retail" OR "store")</em></p>
                    <p>В результате будут найдены все документы, у которых в ключевом слове присутствует слово&nbsp;<b>retail</b>&nbsp;или
                    слово&nbsp;<b>store</b>. Т.е. будут найдены документы с ключевыми словами&nbsp;<b>retail, store,
                        retail sales, online retailing, in store experience, virtual store</b>&nbsp;и т.д.</p>
            </li>
            <li>Для поиска на точное соответствие атрибута введенной фразе.

                <p>Вид такого запроса:<br>
                    <b>Название_атрибута.keyword: (точный_запрос_словосочетание_к_атрибуту)</b></p>

                <p>Пример:<br>
                    <em>systemKeywords.keyword: ("retail")</em><br>
                    В результате будут найдены все документы, ключевые слова которых в точности равны слову&nbsp;<b>retail</b>
                </p>
            </li>
            <li>Поиск наличия атрибута и использование булевых операторов

                <p>Пример:<br>
                    <em>patentFamilyHead: false AND NOT _exists</em>: description_</p>
            </li>
        </ol>
    </section>
</template>

<script>
    export default {
        name: "FullTextSearch",
        data() {
            return {
                section: 'search',
                subSection: 'attributeSearch'
            }
        }
    }
</script>

<style scoped>

</style>