<template>
    <section class="fst-FAQ-item">

        <h3>Начало работы</h3>

        <p>На вкладке <b>Вид 360</b>&nbsp;представлены виджеты и графики.
            Для работы укажите поисковый запрос, выберите период в верхнем левом углу и нажмите на кнопку поиска.</p>
        <span class="image-wrap" style=""><img src="/periodFrom1.png"></span>

        <p>На каждом виджете вы можете нажать ссылку "<span class="iq-text-primary">смотреть все</span>" или на на иконку <i class="fas fa-share-square ml-1 iq-text-primary"></i>, чтобы посмотреть больше информации .</p>
        <p>Нажмите на иконку <i class="fas fa-save ml-1 iq-text-primary"></i> , чтобы скачать изображение виджета</p>
        <p>Вы также можете скачать данные со всех виджетов в формате Excel, для этого нажмите <b>Экспорт в</b> &nbsp;<b>Excel</b>.<br>
            <span class="image-wrap" style=""><img src="/toExcel1.png"></span>
        </p>
    </section>
</template>

<script>
    export default {
        name: "View360Start",
        data() {
            return {
                section: 'search',
                subSection: 'view360'
            }
        }
    }
</script>

<style scoped>

</style>