import { render, staticRenderFns } from "./Morphology.vue?vue&type=template&id=60a2c776&scoped=true&"
import script from "./Morphology.vue?vue&type=script&lang=js&"
export * from "./Morphology.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a2c776",
  null
  
)

export default component.exports