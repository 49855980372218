<template>
    <section class="fst-FAQ-item">

        <h3>Список отчетов</h3>

        <p>Для работы с кластерными отчетами необходимо перейти в раздел "Кластеры":</p>
        <span class="image-wrap"><img src="/clusterEnter1.png"/></span>
        <p>В данном разделе в табличной форме отображаются список созданных отчетов. </p>
        <p>Таблица состоит из следующих полей:</p>
        <ul class="dashed">
            <li>иконка состояния отчета:
                <i class="far fa-spinner fa-fw ml-3"></i> - в процессе создания,
                <i class="far fa-check fa-fw ml-3"></i> сформированный;
            </li>
            <li>название отчета;</li>
            <li>дата создания;</li>
            <li>автор (пользователь который создал отчет);</li>
            <li>поисковый фильтр, на основе которого создан отчет;</li>
            <li>кнопка обновления списка <i class="far fa-sync mx-1"></i>.</li>
        </ul>
        <span class="image-wrap"><img src="/clusterList1.png"/></span>
        <p>Доступные действия с отчетами:</p>
        <ol>
            <li>
                поиск отчета по названию
                <div class="d-inline-flex ml-2" style="width: 150px">
                    <el-input suffix-icon="far fa-search"></el-input>
                </div>
            </li>
            <li>переименование отчета - для редактирования названия необходимо кликнуть на иконку <i class="el-icon-edit fa-fw"></i>;
            </li>
            <li>удаление отчета <i class="far fa-trash-alt fa-fw"></i>;</li>
            <li>переход в рабочую область отчета, кликнув на его название <i class="far fa-check fa-fw ml-2"></i> Robot.</li>
        </ol>
    </section>
</template>

<script>
    export default {
        name: "ClusterList",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersWork'
            }
        }
    }
</script>

<style scoped>

</style>