<template>
    <section class="fst-FAQ-item">

        <h3>Как создать отчет</h3>


        <p>Для создания кластерного отчета необходимо:</p>
        <ol>
            <li>выполнить поиск документов;</li>
            <li>нажать на иконку в виде трёх вертикальных точек, расположенную рядом со строкой поиска и выбрать
                "Кластеризировать запрос";
                <span class="image-wrap"><img src="/clusterStart1.png"/></span>
            </li>
            <li class="mt-4">ввести название создаваемого отчета и нажать иконку <i class="fas fa-play ml-1 iq-text-primary"></i>
                <span class="image-wrap"><img src="/clusterStart2.png"/></span>
            </li>
        </ol>
        <p>Кластеризация выполняется в фоновом режиме и в зависимости от размера поисковой выдачи может занимать
            значительное время (чем больше документов в поисковой выдаче тем длительнее процедура кластеризации).</p>


    </section>
</template>

<script>
    export default {
        name: "ClusterHowToBuild",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersStart'
            }
        }
    }
</script>

<style scoped>

</style>