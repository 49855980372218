import Const from './../commons/const';
import AllowedActions from './../models/AllowedActions';


export default {
    contextUrl: '',
    apiUrl: Const.apiUrl,
    locale: Const.locale,
    mode: 'production',
    rtCompany: {},
    showCustomFacets: true,
    showStructuredQuery: false,
    structuredQuery: null,
    showChatBot: false,
    searchFilter: {},
    roles: [],
    components: [],
    allowedActions: new AllowedActions([]),
    summarization: {
        loading: false,
        collapsed: false,
        active: false,
        show: false,
        lang: 'ru',
        text: '',
        result: {}
    },
    sourcesForSummarization: [
       'Science',
       'ScienceOpen',
       'ScienceOpenAlex',
       'ScienceCrossref',
       'Patents',
       'News',
    ],
    userInfo: {
        id: null,
        state: null,
        roles: [],
        username: null,
        email: null,
        fullName: null,
        dateCreate: null,
        lastYears: {}
    },
    initialized: false,
    customFacets: {},
    serverTimeOffset: 0,
    notions: {
        list: [],
        map: {}
    },
    notionsPromise: null,
    project: {
        name: 'Teqviser',
        version: null
    },
    loading: {
        exportToImage: false,
    },
    sourceTabs: { // ---- для раздела Data Coverage ---
        news: {
            alias: 'news',
            title: 'News',
            dataCoverageFields: [
                {alias: 'docCount', format: 'toFin'},
                {alias: 'docPeriod', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
            ]
        },
        science: {
            alias: 'science',
            title: 'Science',
            dataCoverageFields: [
                {alias: 'docCount', format: 'toFin'},
                {alias: 'docPeriod', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'countryDistribution', type: 'countryData'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
            ]
        },
        scienceOpen: {
            alias: 'scienceOpen',
            title: 'ScienceOpen',
            dataCoverageFields: [
                {alias: 'docCount', format: 'toFin'},
                {alias: 'docPeriod', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'countryDistribution', type: 'countryData'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
            ]
        },
        scienceCrossref: {
            alias: 'scienceCrossref',
            title: 'ScienceCrossref',
            dataCoverageFields: [
                {alias: 'docCount', format: 'toFin'},
                {alias: 'docPeriod', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'countryDistribution', type: 'countryData'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},

            ]
        },
        // scienceOpenAlex: {
        //     alias: 'scienceOpenAlex',
        //     title: 'OpenAlex',
        //     dataCoverageFields: [
        //         {alias: 'docCount', format: 'toFin'},
        //         {alias: 'docPeriod', type: 'yearPeriod'},
        //         {alias: 'updateFrequency', type: 'i18n'},
        //         {alias: 'countryDistribution', type: 'countryData'},
        //         {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
        //     ]
        // },
        patents: {
            alias: 'patents',
            title: 'Patents',
            dataCoverageFields: [
                {alias: 'docCount', format: 'toFin'},
                {alias: 'docPeriod', title: 'docPeriodPatents', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'countryDistribution', type: 'countryData', title: 'countryDistributionPatents'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
            ]
        },
        companies: {
            alias: 'companies',
            title: 'Companies',
        },
        markets: {
            alias: 'marketSize',
            title: 'MarketSize',
        },
        investment: {
            alias: 'investment',
            title: 'Investment',
            titleAlt: 'InvestmentAndCompanies',
            dataCoverageFields: [
                {alias: 'investmentSum', format: 'toFinHuman'},
                {alias: 'investmentCount', format: 'toFin'},
                {alias: 'companyCount', format: 'toFin'},
                {alias: 'docPeriod', type: 'yearPeriod'},
                {alias: 'updateFrequency', type: 'i18n'},
                {alias: 'companyCountryDistribution', type: 'countryData'},
                {alias: 'investmentCountryDistribution', type: 'countryData'},
                {alias: 'lastUpdate', dateFormat: 'DD.MM.YYYY'},
            ]
        },

    }

};
