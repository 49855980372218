<template>
    <section class="fst-FAQ-item">

        <h3>Поисковая выдача</h3>

        <p>Теквайзер содержит более 200 млн. данных, которые включают в себя:</p>

        <p>Научные публикации - на вкладках <b>Наука</b>, <b>Наука (откр.)</b>, <b>Crossref</b>, <b>OpenAlex</b></p>

        <p>Патенты - на вкладке <b>Патенты</b></p>

        <p>Сведения о компаниях и инвестициях - на вкладках <b>Компании</b>, <b>Инвестиции</b>, <b>Рынки</b></p>

        <p>Новостные статьи - на вкладке <b>Новости</b></p>

        <p>Поисковый запрос применяется ко всем вкладкам и вы можете переходить по ним не повторяя поиск.</p>



    </section>
</template>

<script>
    export default {
        name: "SearchTools",
        data() {
            return {
                section: 'search',
                subSection: 'searchResult'
            }
        }
    }
</script>

<style scoped>

</style>