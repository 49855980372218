<template>
    <section class="fst-FAQ-item">

        <h3>Поиск по кластерам</h3>
        <p>Чтобы выполнить поиск среди документов всех кластеров, необходимо ввести поисковый запрос в строку поиска в
            верхней части рабочей области.</p>

        <span class="image-wrap"><img src="/clusterSearch0.png"/></span>

        <p>В результате поиска, на карте кластеров будут подсвечены голубым только те документы, которые соответствуют
            поисковому запросу.</p>

        <span class="image-wrap"><img src="/clusterSearch1.png"/></span>

        <p>Для просмотра найденных документов, необходимо раскрыть карточку кластера (кликнуть по его названию) и нажать
            кнопку <b style="font-variant: all-small-caps; font-size: .85rem;">SHOW DOCUMENTS</b>. В результате будут
            показаны только те документы кластера, которые соответствуют поисковому запросу.</p>


    </section>
</template>

<script>
    export default {
        name: "ClusterSearch",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersWork'
            }
        }
    }
</script>

<style scoped>

</style>