<template>
    <section class="fst-FAQ-item">

        <h3>Рабочая область</h3>
        <p>Рабочая область включает в себя: </p>
        <ol>
            <li>селектор "Report" - выпадающий список сформированных кластеров, для переключения между ними;</li>
            <li>поиск по кластеру - выделение документов на карте кластера в соответствии с поисковым запросом;</li>
            <li>легенда;</li>
            <li>карта кластеров;</li>
            <li>карточка кластера.</li>
        </ol>
        <span class="image-wrap"><img src="/clusterMapApp.png"/></span>
        <p>На карте кластеров, документы отображаются в виде точек, которые объединяются в "облака" (кластеры). Над
            каждым кластером отображается его название. Карту можно перемещать с нажатой левой клавишей мыши.
            С помощью колесика мыши осуществляется Zoom</p>

        <h4>Легенда</h4>
        <table class="figs">
            <tr>
                <td><img src="/clusterLegend1.png"/></td>
                <td><img src="/clusterColoring1.png"/></td>
                <td><img src="/clusterColoring2.png"/></td>
            </tr>
            <tr>
                <td>Легенда</td>
                <td>Схема: <b>Cluster ID</b></td>
                <td>Схема: <b>Growth last year, Growth Avg</b></td>
            </tr>
        </table>

        <ol>
            <li><b>Выбор цветовой схемы кластеров</b> (Coloring by)
                <ul class="dashed ">
                    <li><b>Cluster ID</b> - (значение по умолчанию) - каждому кластеру назначается уникальный цвет;</li>
                    <li>
                        <b>Growth last year</b> - цвет кластера будет определяться исходя из того, увеличивалось или
                        уменьшалось количество документов в кластере за последний год.
                        <div class="mt-1">Цвет определяет рост или падение. Зеленые оттенки - рост, красные - падение.
                        </div>
                        <div class="mt-1"> Чем насыщеннее цвет, тем больше рост/падение;</div>
                    </li>
                    <li><b>Growth Avg</b> - цвет кластера будет определяться как у <span
                            class="b-500">Growth last year</span>, но исходя из значения среднего роста количества
                        документов в кластере.
                    </li>
                </ul>
            </li>
            <li><b>Список кластеров.</b>
                <div class="mt-1">Строка списка состоит из:</div>
                <ul class="dashed narrow">
                    <li>маркера цветовой дифференциации,</li>
                    <li>названия кластера,</li>
                    <li>значения процента количества документов от их общего числа или процента роста(спада), в
                        зависимости от выбранной цветовой схемы
                    </li>
                    <li>иконки <i class="fas fa-eye fa-fw"></i> скрытия/отображения кластера на карте</li>
                </ul>
                <p>Клик по названию подсвечивает выбранный кластер на карте, при это остальные кластеры затемняются.</p>
                <p>Можно выбрать несколько кластеров при нажатой кнопке CTRL.</p>
                <p>Сбросить выбранные кластеры можно с помощью чекбокса All</p>
            </li>
            <li class="mt-4"><b>Отображение/скрытие документов не попавших в кластеры</b> (<i class="fad fa-toggle-off fa-fw"></i> No clusters).
                <div class="mt-2">По умолчанию, в окне легенды скрыт "кластер", содержащий документы, которые не были отнесены
                ни к одному из других кластеров (на карте они отображаются серыми скоплениями точек). Чтобы отобразить
                данный кластер, необходимо включить переключатель <i class="fad fa-toggle-off fa-fw"></i> "No clusters" в нижней части окна с перечнем
                кластеров. Кластер отобразиться на последней позиции в списке и будет называться "-1 No clusters".</div>
                <span class="image-wrap"><img src="/clusterNoclusters.png"/></span>

            </li>
            <li><b>Отключение видимости названий кластеров на карте</b> (<i class="fad fa-toggle-on fa-fw"></i> Labels)</li>
        </ol>

        <h4 class="mt-5">Инструменты </h4>

        <p>В нижней части карты расположены инструменты:</p>
        <span class="image-wrap"><img src="/clusterTools1.png"/></span>
        <ul class="dashed">
            <li>кнопка
                <el-button size="mini" type="info" plain>
                    <i class="fas fa-mouse-pointer fa-fw"></i></el-button>
                (активирована по умолчанию) - выбор объектов на
                карте выполняется кликом мыши. Для
                одновременного выбора нескольких объектов необходимо нажимать клавишу CTRL.
            </li>
            <li>кнопка
                <el-button size="mini" type="info" plain>
                    <i class="fas fa-lasso fa-fw"></i></el-button>
                - позволяет выбрать на карте произвольную область.
                <br>
                Для выбора произвольной области необходимо выбрать точку начала области (один раз кликнуть левой
                клавишей мыши), плавно очертить необходимую область, завершить построение, повторно кликнув левой клавишей мыши.

                <span class="image-wrap d-flex flex-center" style="gap: 15px">
                    <img src="/clusterLasso1.png"/>
                    &nbsp;
                    <img src="/clusterLasso2.png"/>
                </span>
                <p>Для построенной области откроется карточка с агрегированными данными (карточка появляется спустя
                    некоторое
                    время, необходимое на агрегацию данных),
                    а на карте отобразиться количество выделенных документов
                    <span class="px-2 py-1 d-inline-block iq-bg-info small"><i
                            class="fas fa-copy mr-1"></i> 6 266</span></p>
                <p>Клик по данному элементу открывает новую вкладку браузера с найденными документами.</p>
            </li>
            <li class="mt-4">кнопка
                <el-button size="mini" type="info" plain>RESET</el-button>
                сбрасывает выделение
            <li>кнопка
                <el-button size="mini" type="info" plain><i class="fas fa-expand-wide fa-fw"></i></el-button>
                убирает все управляющие элементы с карты и переводит ее в режим полного экрана,<br>
                для возврата в исходный режим нажмите кнопку <b>ESC</b>;
            </li>
            <li>последним элементом идет меню экспорта карты в PNG в двух форматах - для светлого фона и темного</li>
        </ul>


    </section>
</template>

<script>
    export default {
        name: "ClusterApp",
        data() {
            return {
                section: 'clusters',
                subSection: 'clustersWork'
            }
        }
    }
</script>

<style scoped>

</style>