<template>
    <div class="fst-NotionsEditor">
        <el-drawer
                title="Edit Notion"
                :visible.sync="showNotionEditor"
                :with-header="false"
                :wrapperClosable="false"
                size="850px"
                direction="ltr">
            <div class="drawer-container">
                <div class="header">
                    <div class="title">
                        <span v-if="value.id"><i class="far fa-pencil mr-1 iq-text-blue"></i>
                            {{ $t('admin.notions.editNotion') }}
                        </span>
                        <span v-else><i class="far fa-plus mr-1 iq-text-blue"></i>
                            {{ $t('admin.notions.addNotion') }}
                        </span>
                    </div>
                    <div>
                        <i class="far fa-times close" @click="$emit('closeNotionEditor')"></i>
                    </div>

                </div>
                <div class="body">
                    <div class="iq-attr-block-3">
                        <div v-if="model.dateCreate">
                            <label for=""> {{ $t('admin.notions.table.dateCreate') }}</label>
                            <span class="iq-text-secondary">{{ model.dateCreate }}</span>
                        </div>
                        <div v-if="model.dateUpdate">
                            <label for=""> {{ $t('admin.notions.table.dateUpdate') }}</label>
                            <span class="iq-text-secondary">{{ model.dateUpdate }}</span>
                        </div>
                        <div v-if="model.creator">
                            <label for="">{{ $t('admin.notions.table.creator') }}</label>
                            <span class="iq-text-secondary">{{ model.creator.fullName }}</span>
                        </div>
                    </div>
                    <div class="form" ref="form">
                        <div class="icon-systemCheck">
                            <div class="upload">
                                <el-upload action="" ref="upload" :auto-upload="false" :limit="1"
                                           :show-file-list="false"
                                           :on-change="handleUploadIcon">
                                    <div slot="trigger">
                                        <el-image class="icon" v-if="model.icon"
                                                  :src="`data:image/webp;base64,${model.icon}`"></el-image>
                                        <template v-else>
                                            <i class="fas fa-camera iq-text-primary icon"></i>
                                            <el-button slot="tip" type="text">
                                                {{ $t('admin.notions.table.selectIcon') }}
                                            </el-button>
                                        </template>
                                    </div>
                                    <template slot="tip">
                                        <el-link type="primary" icon="fas fa-trash-alt ml-4" :title="$t('delete')"
                                                 v-if="model.icon"
                                                 :underline="false"
                                                 @click="handleRemoveIcon"></el-link>
                                    </template>
                                </el-upload>
                            </div>
                            <div class="systemCheck" v-if="userIsAdmin">
                                <el-checkbox v-model="model.system" border size="medium"
                                             @change="handleIsSystemCheckbox">
                                    System notion
                                    <i class="fas fa-exclamation-triangle ml-1" v-if="model.system"></i>
                                </el-checkbox>
                            </div>
                        </div>

                        <div>
                            <label> {{ $t('name') }}</label>
                            <el-input v-model="model.name" clearable></el-input>
                        </div>
                        <div>
                            <label>{{ $t('admin.notions.table.description') }}</label>
                            <el-input v-model="model.description" type="textarea" :rows="2" resize="none"></el-input>
                        </div>
                        <div class="relative">
                            <label>
                                {{ $t('admin.notions.table.querySciencePatents') }}
                            </label>
                            <div class="query-block">
                                <div>

                                    <ValidateQuery v-model="queryToValidate.querySciencePatents"
                                                   popoverWidth="800"
                                                   @validationComplete="handleSaveDisabled"/>

                                    <SearchQueryEditor v-model="model.querySciencePatents"
                                                       @input="handleInput('querySciencePatents')"/>
                                </div>
                                <el-tooltip effect="light" content="Help" :open-delay="606">
                                    <el-button type="text" class="help-btn" @click="handleShowHelp"><i
                                            class="fa-question-circle fas"></i></el-button>
                                </el-tooltip>

                            </div>
                        </div>
                        <div class="relative">
                            <label>
                                <div>
                                    {{ $t('admin.notions.table.queryNewsCompanies') }}
                                </div>
                                <div class="copy-query-block">
                                    <el-tooltip class="item" effect="dark"
                                                content="Copy query to Science & Patents"
                                                placement="top-start" :open-delay="1500">
                                        <el-button type="text" @click="copyQuery('toSciPat')">
                                            <i class="far fa-arrow-alt-up mr-1"></i>
                                        </el-button>
                                    </el-tooltip>

                                    <i class="fas fa-copy iq-text-blue"></i>

                                    <el-tooltip class="item" effect="dark"
                                                content="Copy query to News & Companies"
                                                placement="top-start" :open-delay="1500">
                                        <el-button type="text" @click="copyQuery('toNewsComp')" class="ml-0">
                                            <i class="far fa-arrow-alt-down mr-1"></i>
                                        </el-button>
                                    </el-tooltip>
                                </div>
                            </label>
                            <div class="query-block">
                                <div>
                                    <ValidateQuery v-model="queryToValidate.queryNewsCompanies"
                                                   popoverWidth="800"
                                                   @validationComplete="handleSaveDisabled"/>
                                    <SearchQueryEditor v-model="model.queryNewsCompanies"
                                                       @input="handleInput('queryNewsCompanies')"/>
                                </div>
                                <el-tooltip effect="light" content="Help" :open-delay="606">
                                    <el-button type="text" class="help-btn" @click="handleShowHelp"><i
                                            class="fa-question-circle fas"></i></el-button>
                                </el-tooltip>
                            </div>
                        </div>

                        <div v-if="userIsAdmin && model.system">
                            <label> {{ $t('admin.notions.table.attributeQuery') }}</label>
                            <el-input v-model="model.attributeQuery" type="textarea"
                                      :autosize="{ minRows: 2, maxRows: 6}"></el-input>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="iq-select-like">
                        <template v-if="isAllowedCommonNotions">
                            <label for="">{{ $t('admin.notions.table.visibleByCompany') }}: </label>
                            <el-select v-model="model.company.id" placeholder="Choose company">
                                <el-option key="comp-0" :label="$t('allCompanies')" value=""></el-option>
                                <el-option v-for="item in companiesList"
                                           :key="`comp-${item.id}`"
                                           :label="item.name"
                                           :value="item.id"
                                ></el-option>
                            </el-select>
                        </template>
                    </div>
                    <div>
                        <el-button @click="$emit('closeNotionEditor')" type="text">
                            {{ $t('cancel') }}
                        </el-button>
                        <el-button @click="this.save" type="primary" :disabled="saveDisabled">
                            {{ $t('save') }}
                        </el-button>
                    </div>

                </div>
            </div>
        </el-drawer>
    </div>

</template>

<script>
    import {USER_ROLES} from "@/models/Users";
    import ValidateQuery from "@/commons/components/ValidateQuery";
    import Help from '../search/Help.vue';

    import _ from "lodash";
    import SearchListItem from "@/components/search/SearchListItem";

    export default {
        name: "NotionsEditor",
        components: {
            ValidateQuery,
            Help,
            SearchQueryEditor: () => import('@/libs/ace/SearchQueryEditor')
        },
        props: {
            value: {
                type: Object,
                default: () => ({})
            },
            showNotionEditor: {
                type: Boolean,
                default: false
            },
            companies: {
                type: Array
            }
        },
        data() {
            this.defaultModel = {
                id: null,
                name: '',
                querySciencePatents: '',
                queryNewsCompanies: '',
                attributeQuery: '',
                description: '',
                icon: '',
                system: false,
                company: {
                    id: ''
                }
            };
            return {
                superUsers: ['watson', 'test'],
                model: Object.assign({}, this.defaultModel, this.value),
                user: this.$store.state.userInfo,
                companiesList: {},
                saveDisabled: false,
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                queryToValidate: {
                    queryNewsCompanies: '',
                    querySciencePatents: ''
                },
                isAllowedCommonNotions: this.$store.state.allowedActions.contains('CREATE_PUBLIC_NOTION'),
                rules: {
                    'name': [{required: true, trigger: 'blur'}]
                },
                buttons: [
                    {text: 'AND', value: 'AND'},
                    {text: 'OR', value: 'OR'},
                    {text: 'NOT', value: 'NOT'},
                    {text: '" "', value: '""', cursorOffset: 1},
                    {text: '( )', value: '()', cursorOffset: 1},
                    {text: '( ) DIST 5', value: '() DIST 5', cursorOffset: 8},
                    {text: '@', value: '@'}
                ],
                editor: {
                    queryNewsCompanies: {}
                }
            }
        },
        mounted() {
            this.handleInputDebounced = _.debounce(this.operateInput, 508);
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            value(newVal) {
                this.refresh();
            }
        },
        methods: {
            refresh() {
                this.model = Object.assign({}, this.defaultModel, this.value)
                if (this.userIsAdmin) {
                    this.companiesList = _.cloneDeep(this.companies);
                } else if (this.user.company && this.user.company.id) {
                    this.companiesList = _.cloneDeep(this.companies.filter(it => it.id === this.user.company.id));
                } else {
                    this.companiesList = {}
                }
                // console.log('uI', this.userInfo);
                // console.log('allo---', this.allowed, this.allowed.contains('CREATE_PUBLIC_NOTION'));

            },
            save() {
                return new Promise((resolve, reject) => {
                    let valid = (this.model.name.length > 0 && (this.model.querySciencePatents.length + this.model.queryNewsCompanies.length + this.model.attributeQuery.length > 0));
                    if (valid) {
                        let model = _.merge({}, _.pick(this.model, _.keys(this.defaultModel)));

                        model.querySciencePatents = _.trim(this.model.querySciencePatents.replace(/“|”/g, '"')
                            .replace(/[\u25A0\u00A0\s]/g, ' ')
                            .replace(/\s\s+/g, ' '));
                        model.queryNewsCompanies = _.trim(this.model.queryNewsCompanies.replace(/“|”/g, '"')
                            .replace(/[\u25A0\u00A0\s]/g, ' ')
                            .replace(/\s\s+/g, ' '));

                        model.name = _.trim(model.name);

                        if (!this.userIsAdmin) {
                            delete model.system;
                            delete model.attributeQuery;
                        }

                        // console.log('save model ->', model);
                        if (model && model.company && !model.company.id) {
                            model.company = (this.userInfo.company && !this.isAllowedCommonNotions) ? {
                                id: this.userInfo.company.id
                            } : null
                        }
                        this.$services.notions.save(model).prepare({loading: {target: this.$el}})
                            .then(resp => {
                                resolve(resp);
                                this.$message({
                                    type: 'success',
                                    showClose: true,
                                    message: this.$t('admin.notions.editor.msg.success')
                                });
                                this.$emit('closeNotionEditor', resp.data);
                                this.$store.dispatch('updateNotions');
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    } else {
                        console.error(`${this.model.id ? 'Edit' : 'Create'} notion validation failed`);
                        this.$message({
                            message: `${this.model.id ? 'Edit' : 'Create'} notion validation failed: all queries are empty`,
                            type: 'warning',
                        });
                    }
                });
            },
            copyQuery(direction) {
                if (direction === 'toSciPat') {
                    this.model.querySciencePatents = _.clone(this.model.queryNewsCompanies);
                } else {
                    this.model.queryNewsCompanies = _.clone(this.model.querySciencePatents);
                }
            },
            validateQuery(type) {
                this.queryToValidate[type] = _.clone(this.model[type]);
            },
            validationComplete(result) {
                if (!result.status) {
                    console.log('errors::::::::', result.errors);
                    this.validationResult = true;
                } else {
                    this.validationFalse = false;
                }
            },

            handleInput(type) {
                this.handleInputDebounced(type);
            },
            operateInput(type) {
                this.validateQuery(type)
            },
            handleShowHelp() {
                window.open(this.$router.resolve({name: 'FAQ'}).href + '/SearchOperators', '_blank');
            },
            handleShowHelp2() {
                this.$modal.dialog(Help, {
                    title: "Help",
                    params: {
                        style: 'background: #fff;'
                    },
                    buttons: [{
                        name: 'Close',
                        type: 'text',
                        handler: data => {
                            data.dialog.close();
                        }
                    }]
                }).catch(() => {
                });

            },
            handleUploadIcon(file) {
                let reader = new FileReader();
                reader.readAsDataURL(file.raw);
                reader.onload = () => {
                    this.model.icon = reader.result.split(',').pop();
                }
            },
            handleRemoveIcon() {
                this.model.icon = '';
                this.$refs.upload.clearFiles();
            },
            handleSaveDisabled(rez) {
                this.saveDisabled = !rez.status;
            },
            handleIsSystemCheckbox(status) {
                this.$confirm('Are you sure?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then(() => {
                    this.model.system = status;
                    console.log('Ok Model', this.model);
                }).catch(() => {
                    this.model.system = !status;
                    console.log('Cancel Model', this.model);
                });
            }
        }
    }
</script>

<style lang="scss">
    .fst-NotionsEditor {
        .drawer-container {
            background: #fff;
        }

        label {
            display: block;
            font-weight: 500;
            line-height: 32px;
            color: $iq-color-text-regular;

            .copy-query-block {
                margin: -11px 33px 0 0;
            }

            .fa-copy {
                margin-right: 3px;
            }
        }

        .form {
            > div {
                margin-bottom: 12px;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .el-textarea__inner,
            .el-input__inner {
                padding: 5px 7px;
            }
        }

        .semiround {
            textarea {
                border-radius: 5px 5px 0 0;
                border-bottom: none
            }
        }

        .icon-systemCheck {
            display: flex;
            align-items: center;
            justify-content: space-between;

            //.el-checkbox__input {
            //    .el-checkbox__inner {
            //        border-radius: 4px;
            //        width: 16px;
            //        height: 16px;
            //    }
            //
            //    &.is-checked {
            //        .el-checkbox__inner {
            //            background-color: #3064F2;
            //            border-color: #3064F2;
            //
            //            &::after {
            //                left: 4px;
            //                top: 1px;
            //                width: 4px;
            //                border-width: 2px;
            //            }
            //        }
            //    }
            //}
        }

        .upload {
            > div {
                display: flex;
                align-items: center;

                .el-button {
                    font-size: 15px;
                    font-weight: 500;
                    padding: 0 7px;
                    margin-right: 10px;
                }

                .el-upload > div {
                    display: flex;
                    align-items: center;
                }
            }

            .icon {
                border: 1px solid $iq-border-color-base;
                border-radius: 5px;
                width: 50px;
                height: 50px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

                img.el-image {
                    max-width: 100%;
                }
            }

            .div__content > div {
                display: flex;
                align-items: center;
            }
        }

        .fst-ValidateQuery {
            position: relative;

            .gotErrorMarks {
                i {
                    top: 6px;
                    left: -7px;
                }
            }
        }

        .el-button.help-btn {
            cursor: pointer;
            color: $iq-color-blue-medium;
            font-size: 20px;
            padding: 3px 0 3px 15px;

            &:hover {
                color: $iq-color-blue;
            }
        }

        .query-block {
            display: flex;
            width: 100%;
            align-items: center;

            > div {
                flex: 1;
            }
        }
    }
</style>
